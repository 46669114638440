import { Theme } from '@mui/material/styles';

export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        select: {
          whiteSpace: 'break-spaces',
          //fontSize: '1.2rem',
          color: theme.palette.text.secondary,
          '& span > svg': {
            color: theme.palette.text.secondary,
          },
        },
      },
    },
  };
}
