import { Theme, alpha } from '@mui/material/styles';
//import { pxToRem } from '../../paletteFuncs';

export default function Input(theme: Theme) {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          lineHeight: 'normal',
          fontSize: 16,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          lineHeight: 1.5,
          fontSize: 18,
          '& svg': { color: '#fff', fontSize: 18 },
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.disabled },
          },
        },
        // input: {
        //   '&::placeholder': {
        //     opacity: 1,
        //     color: theme.palette.text.disabled,
        //   },
        // },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottom: '1px solid rgb(196 196 196)',
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid rgb(97, 87, 255, 0.3)',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          lineHeight: 1.5,
          fontSize: 18,
          color: theme.palette.grey[100],
          '&.Mui-focused': {
            color: '#fff',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(theme.palette.grey[500], 0.12),
          '&:hover': {
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.action.focus,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: alpha(theme.palette.grey[500], 0.56),
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: theme.spacing(1),
          backgroundColor: theme.palette.primary.light,
          '&.Mui-focused': {
            backgroundColor: theme.palette.primary.dark,
            //borderColor: '#fff',
            //borderWidth: '2px',
          },
          '& fieldset': {
            borderColor: '#fff',
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.primary.main, 0.2),
          },
          '&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff', // root border color
            borderWidth: '2px',
            //backgroundColor: '#fff',
          },
          '&.Mui-focused fieldset': {
            borderWidth: '2px',
            //backgroundColor: '#fff',
            borderColor: '#fff',
            '&.MuiOutlinedInput-notchedOutline': {
              borderColor: '#fff',
            },
          },
        },
      },
    },
  };
}
