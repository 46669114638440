import i18next from 'i18next';

export default function validate(values, appInfo) {
  let errors = {};
  //console.log('=== authIdentifiers ===', appInfo?.authIdentifiers);
  if (values?.login != null && !values?.login.trim()) {
    switch (appInfo?.authIdentifiers) {
      case 'email': {
        errors.login = i18next.t('SIGN.UP_EMPTY_EMAIL');
        break;
      }
      case 'phone': {
        errors.login = i18next.t('SIGN.UP_EMPTY_PHONE');
        break;
      }
      default: {
        errors.login = i18next.t('SIGN.UP_EMPTY_EMAIL_OR_PHONE');
      }
    }
  }

  if (values?.password != null && !values?.password.trim()) {
    errors.password = i18next.t('SIGN.UP_EMPTY_PASS');
  }

  return errors;
}
