// ----------------------------------------------------------------------

import { Theme } from '@mui/material/styles';
//import { pxToRem } from '../../paletteFuncs';

export default function IconButton(theme: Theme) {
  return {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          lineHeight: 'normal',
          fontSize: 18,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#fff',
          lineHeight: '',
          fontSize: 20,
          backgroundColor: theme.palette.primary.dark,
          fontWeight: 500,
          '&:hover': {
            color: `${theme.palette.primary.dark} !important`,
            background: '#fff',
            boxShadow: theme.palette.shadows.card,
          },
          '& span > svg': {
            color: theme.palette.hero,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
              color: `${theme.palette.primary.dark} !important`,
              background: '#fff',
            },
          },
        },
      },
    },
  };
}
