import React, { useMemo } from 'react';
import { TextField as Input } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiUIPicker from '../MUIDatePicker';

import moment from 'moment';
import {
  MAX_BIRTH_DATE_CHILD,
  MIN_BIRTH_DATE_CHILD,
} from '../../pages/visit/validationCreateVisit';

function ChildForm({
  values,
  errors,
  handleChange,
  setValues,
  setErrors,
  lang,
  validate,
  appInfo,
}) {
  const { t } = useTranslation();

  const handleDateChange = (childBirthDate) => {
    childBirthDate =
      childBirthDate != null ? new Date(childBirthDate) : null;
    const vals = {
      ...values,
      childBirthDate,
    };

    setValues(vals);
    setErrors(validate(vals, appInfo));
  };

  return useMemo(
    () => (
      <>
        <Input
          name="childLastName"
          variant="outlined"
          value={values?.childLastName || ''}
          onChange={handleChange}
          margin="normal"
          label={t('COMPONENT.FORM_LAST_NAME')}
          type="text"
          fullWidth
          required
          inputProps={{ maxLength: 50 }}
          error={errors?.childLastName != null}
          helperText={
            errors?.childLastName != null && errors?.childLastName
          }
        />
        <Input
          name="childFirstName"
          variant="outlined"
          value={values?.childFirstName || ''}
          onChange={handleChange}
          margin="normal"
          label={t('COMPONENT.FORM_NAME')}
          type="text"
          fullWidth
          required
          inputProps={{ maxLength: 50 }}
          error={errors?.childFirstName != null}
          helperText={
            errors?.childFirstName != null && errors?.childFirstName
          }
        />
        <Input
          name="childMiddleName"
          variant="outlined"
          value={values?.childMiddleName || ''}
          onChange={handleChange}
          margin="normal"
          label={t('COMPONENT.FORM_MIDLE_NAME')}
          type="text"
          fullWidth
          inputProps={{ maxLength: 50 }}
          error={errors?.childMiddleName != null}
          helperText={
            errors?.childMiddleName != null && errors?.childMiddleName
          }
        />
        <MuiUIPicker
          inputFormat={lang === 'ru' ? 'DD.MM.YYYY' : 'MM/DD/YYYY'}
          //id="date-picker-inline"
          label={t('COMPONENT.BIRTH_DATE')}
          value={
            values?.childBirthDate != null
              ? values?.childBirthDate
              : null
          }
          handleChange={handleDateChange}
          defaultCalendarMonth={moment().add(
            MIN_BIRTH_DATE_CHILD,
            'years',
          )}
          minDate={moment().add(MIN_BIRTH_DATE_CHILD, 'years')}
          maxDate={
            MAX_BIRTH_DATE_CHILD === 0
              ? moment()
              : moment().add(MAX_BIRTH_DATE_CHILD, 'years')
          } //maxDate
          margin="normal"
          // invalidDateMessage={t('COMPONENT.INVALID_BIRTH_DATE')}
          // minDateMessage={t('COMPONENT.INVALID_BIRTH_DATE')}
          // maxDateMessage={t('COMPONENT.INVALID_BIRTH_DATE')}

          required
          errorText={errors?.childBirthDate}
        />
      </>
    ),

    [values, errors],
  );
}

export default ChildForm;
