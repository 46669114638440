import React from 'react';
import Modale from '../../components/Modals/Modale';
// import {
//   formatHL7DateToSrtDate,
//   formatHL7DateToTime,
// } from '../../helpers/dateFormat';
import CreateVisit from './CreateVisit';

//import { useVisitState } from '../../context/VisitContext';
import { useUserStateDispatch } from '../../context/UserContext';
import Sign from './Sign';
import AnonymousNotEnabled from './AnonymousNotEnabled';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function ModaleCreateVisit({
  modalCreateVisit,
  toggleModalCreateVisit,
}) {
  // const {
  //   state: {
  //     doctor: { visitKinds },
  //     visitDate,
  //     selectedVisitKindId,
  //   },
  // } = useVisitState();
  const {
    userState: {
      isAuthenticated,
      user: { isAnonymous },
      appInfo,
    },
  } = useUserStateDispatch();
  const anonymousNotEnabled =
    isAnonymous && !appInfo.isAnonymousVisitsEnabled;

  // const visitKindName = visitKinds.find(
  //   (it) => it.id === selectedVisitKindId,
  // )?.name;

  return (
    <Modale
      open={modalCreateVisit}
      toggleModal={toggleModalCreateVisit}
      // title={
      //   visitDate != null && isAuthenticated
      //     ? `${formatHL7DateToSrtDate(visitDate)}${
      //         formatHL7DateToTime(visitDate) !== '00:00'
      //           ? ' ' + formatHL7DateToTime(visitDate)
      //           : ''
      //       }${visitKindName ? '. ' + visitKindName : ''}`
      //     : null
      // }
      maxWidth={isAuthenticated ? 'md' : 'sm'}
      height={anonymousNotEnabled ? 220 : 740}
    >
      {isAuthenticated ? (
        anonymousNotEnabled ? (
          <AnonymousNotEnabled />
        ) : (
          <CreateVisit toggleModal={toggleModalCreateVisit} />
        )
      ) : (
        <Sign toggleModal={toggleModalCreateVisit} />
      )}
    </Modale>
  );
}
