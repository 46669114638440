import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import { useVisitState } from '../../context/VisitContext';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function SelectVisitKinds() {
  const { t } = useTranslation();
  const {
    state: {
      doctor: { isLoaded, visitKinds },
      selectedVisitKindId,
      selectedClinicId,
      selectedSpecId,
    },
    setState,
  } = useVisitState();

  const handleChange = (e) => {
    setState({
      type: 'SET_VISIT_KIND',
      payload: e.target.value,
    });
  };

  const value = (visitKinds || []).find(
    (item) => item?.id === selectedVisitKindId,
  )?.id;
  //console.log('selectedVisitKindId', selectedVisitKindId, value);
  return (
    <FormControl fullWidth>
      <InputLabel id="visit-kinds-label">
        {t(`COMPONENT.FORM_VISIT_KIND`)}
      </InputLabel>
      <Select
        variant="outlined"
        labelId="visit-kinds-label"
        id="visit-kinds"
        value={value || null}
        label={t(`COMPONENT.FORM_VISIT_KIND`)}
        onChange={handleChange}
        disabled={
          !isLoaded ||
          selectedClinicId == null ||
          selectedSpecId == null
        }
      >
        {visitKinds.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <Typography variant="body1">{item.name}</Typography>
            {item.description && (
              <Typography variant="body2">
                {item.description}
              </Typography>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
