import { Theme, alpha } from '@mui/material/styles';

export default function Picker(theme: Theme) {
  return {
    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          width: 'auto',
          margin: 'auto',

          [theme.breakpoints.down('lg')]: {
            width: 290,
          },
        },
        content: {
          minWidth: 288,
          margin: 0,
          backgroundColor: 'transparent',
          '& .MuiPickersDay-root': {
            fontSize: '0.7rem !important',
            '&:hover': {
              color: theme.palette.primary.light,
              background: alpha(theme.palette.primary.main, 0.08),
            },
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          width: 'auto',
          lineHeight: 'normal',
          fontSize: 14,
          [theme.breakpoints.down('lg')]: {
            width: 290,
          },
          '& .MuiPickersCalendarHeader-root ': {
            fontSize: 14,
          },
          '& .MuiPickersCalendarHeader-label ': {
            fontSize: 14,
          },

          margin: 0,
        },
      },
    },
  };
}
