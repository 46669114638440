import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
//import DialogTitle from "@mui/material/DialogTitle";
import Slide from '@mui/material/Slide';
import { Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';
import Marked from 'react-markdown';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden',
  },
  containerInfo: {
    padding: '32px 64px',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '8px 8px',
    },
  },
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: 24,
      [theme.breakpoints.down('md')]: {
        borderRadius: 4,
      },
    },
  },

  marked: {
    '& p': { margin: theme.spacing(2) },
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function AlertDialogSlide({
  isOpen,
  contentTextOk,
  contentTextOkDetail,
  severity = 'success',
  onCloseAlert,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(isOpen);

  const handleClose = () => {
    setOpen(false);
    if (onCloseAlert != null) onCloseAlert();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      classes={{
        root: classes.root,
      }}
    >
      <DialogContent className={classes.container}>
        <Alert severity={severity}>
          <Typography variant="h6" component="p" mb={4}>
            {contentTextOk}
          </Typography>
          {contentTextOkDetail && (
            <Typography
              variant="h6"
              className={classes.marked}
              style={{
                textAlign:
                  contentTextOkDetail.length > 40 ? 'left' : 'center',
              }}
            >
              {<Marked>{contentTextOkDetail}</Marked>}
            </Typography>
          )}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="primary"
        >
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
}
