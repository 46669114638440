// ----------------------------------------------------------------------

import { Theme } from '@mui/material/styles';

export default function Button(theme: Theme) {
  return {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'inherit',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'inherit',
          borderRadius: theme.spacing(2),
          padding: `${theme.spacing(1.5)} ${theme.spacing(2.5)}`,
          fontStyle: 'normal',
          fontWeight: 500,
          boxShadow: 'none',

          border: 0,
          '&:hover': {
            border: 0,
            background: 'transparent',
          },
          '&.Mui-disabled': {
            background: `${theme.palette.action.disabledBackground} !important`,
            color: `${theme.palette.grey[400]} !important`,
          },
          '&.Mui-select': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            border: '1px solid #bdbdbd',
          },
        },

        textPrimary: {
          color: theme.palette.secondary.main,
          '&:hover': {
            color: `${theme.palette.secondary.light} !important`,
          },
        },
        textSecondary: {
          color: theme.palette.secondary.main,
          '&:hover': {
            color: `${theme.palette.secondary.light} !important`,
          },
        },

        containedPrimary: {
          color: '#fff',
          minHeight: 62,

          background: theme.palette.primary.dark,
          boxShadow: theme.palette.baseButton.shadow,
          '&:hover': {
            background: `${theme.palette.primary.darker} !important`,
            boxShadow: theme.palette.shadows.card,
          },
        },

        containedSecondary: {
          color: theme.palette.hero,
          background: theme.palette.bgLight.one,
          boxShadow: theme.palette.baseButton.shadow,
          '&:hover': {
            color: theme.palette.primary.main,
            background: theme.palette.bgLight.two,
            boxShadow: 'none',
          },
        },
        outlinedPrimary: {
          color: '#fff',
          background: theme.palette.primary.dark,
          '&:hover': {
            color: theme.palette.primary.dark,
            background: `#fff !important`,
            boxShadow: theme.palette.shadows.card,
          },
        },
        containedSuccess: {
          color: '#fff',
          backgroundColor: theme.palette.success.main,
          '&:hover': {
            backgroundColor: theme.palette.success.light,
            boxShadow: 'none',
          },
        },
        containedWarning: {
          color: '#fff',
          backgroundColor: theme.palette.warning.main,
          '&:hover': {
            backgroundColor: theme.palette.warning.light,
            boxShadow: 'none',
          },
        },
        containedInfo: {
          color: '#fff',
          backgroundColor: theme.palette.info.main,
          '&:hover': {
            backgroundColor: theme.palette.info.light,
            boxShadow: 'none',
          },
        },
      },
    },
  };
}
