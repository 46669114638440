// ----------------------------------------------------------------------

import { Theme, alpha } from '@mui/material';

export default function Menu(theme: Theme) {
  return {
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow:
            '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: `${theme.spacing(1.5)} ${theme.spacing(2.5)}`,
          '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          },
        },
      },
    },
  };
}
