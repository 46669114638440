import React, { useMemo } from 'react';
import {
  Alert,
  Button,
  Fade,
  Grid,
  TextField as Input,
  Typography,
} from '@mui/material';
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import isEmpty from '../../helpers';
import ConfirmForm from './ConfirmForm';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(2),
    textTransform: 'none',
    width: '100%',
  },
  errorMessage: {
    //textAlign: 'center',
    marginBottom: theme.spacing(4),
    width: '100%',
  },

  alignCenter: {
    textAlign: 'center',
  },
  inputRoot: {
    // borderRadius: theme.spacing(3),
    // lineHeight: 'normal',
    // fontSize: 32,
    // fontWeight: 500,
    // padding: '12px 12px',
  },
  // labelRoot: {
  //   fontSize: 20,
  //   '&$labelFocused': {
  //     color: 'purple',
  //   },
  // },
}));

function OtpLoginForm({
  values,
  errors,
  serverResponse,
  handleSubmit,
  handleChange,
  isLoading,
  isConfirmForm,
  handlePhoneChange,
  phoneMask,
  getNewCode,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const disableButton = values?.login == null || !isEmpty(errors);

  return useMemo(
    () => (
      <>
        <Typography
          variant="h5"
          style={{ textAlign: 'center', marginBottom: 86 }}
        >
          {isConfirmForm
            ? t('SIGN.UP_CONFIRM_CODE')
            : t('VISIT.OTP_LOGIN')}
        </Typography>
        <Fade
          styte={{ width: '100%' }}
          in={serverResponse?.action != null}
          style={
            serverResponse?.action != null
              ? { display: 'flex' }
              : { display: 'none' }
          }
        >
          <Alert
            severity={
              serverResponse?.action === 'OK' ? 'success' : 'warning'
            }
            className={classes.errorMessage}
          >
            <Typography variant="body2">
              {serverResponse?.action === 'OK'
                ? t('SIGN.UP_OK')
                : serverResponse?.action === 'CONFIRM_CODE_SENT' &&
                  serverResponse?.deliveryMethod === 'Email'
                ? t('SIGN.CH_CONFIRM_CODE_SENT_EMAIL')
                : serverResponse?.action === 'CONFIRM_CODE_SENT' &&
                  serverResponse?.deliveryMethod === 'Sms'
                ? t('SIGN.CH_CONFIRM_CODE_SENT_SMS')
                : serverResponse?.action === 'CONFIRM_CODE_SENT' &&
                  serverResponse?.deliveryMethod === 'VoiceCall'
                ? t('SIGN.CH_CONFIRM_CODE_SENT_VOICE')
                : serverResponse?.action === 'WRONG_LOGIN_OR_PASSWORD'
                ? t('SIGN.UP_WRONG_LOG_OR_PASS')
                : serverResponse?.action ===
                  'EMAIL_ALREADY_REGISTERED'
                ? t('SIGN.UP_EMAIL_ALREADY_REG')
                : serverResponse?.action === 'MAY_LOGIN'
                ? t('SIGN.UP_MAY_LOGIN')
                : serverResponse?.action === 'WRONG_CONFIRMATION_CODE'
                ? t('SIGN.UP_CONFIRM_CODE_WRONG')
                : serverResponse?.text}
            </Typography>
          </Alert>
        </Fade>
        {isConfirmForm ? (
          <ConfirmForm
            values={values}
            errors={errors}
            handleChange={handleChange}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            getNewCode={getNewCode}
            timerNumder={
              serverResponse?.lifeTime != null
                ? serverResponse?.lifeTime
                : 300
            }
          />
        ) : serverResponse?.action === 'ACCOUNT_IS_NOT_CONFIRMED' ? (
          <Button
            onClick={() => {
              getNewCode(true);
            }}
            variant="contained"
            color="secondary"
            className={classes.submitButton}
          >
            {t('COMPONENT.BUTTON_NEW_CONFIRM_CODE')}
          </Button>
        ) : serverResponse?.action !== 'OK' ? (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputMask
                  mask={phoneMask}
                  value={values?.login}
                  onChange={handlePhoneChange}
                >
                  {() => (
                    <Input
                      name="login"
                      variant="outlined"
                      value={values?.login}
                      focused
                      margin="normal"
                      //label={t('COMPONENT.FORM_PHONE')}
                      placeholder={t('COMPONENT.FORM_PHONE')}
                      type="text"
                      fullWidth
                      required
                      error={errors?.login != null}
                      helperText={
                        errors?.login != null && errors?.login
                      }
                      InputProps={{
                        classes: {
                          root: classes.inputRoot,
                        },
                      }}
                      // InputLabelProps={{
                      //   classes: {
                      //     root: classes.labelRoot,
                      //   },
                      // }}
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.submitButton}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={disableButton}
                >
                  {t('COMPONENT.BUT_SEND_CODE')}
                </Button>
              </Grid>
            </Grid>
          </>
        ) : null}
      </>
    ),

    [values, errors, serverResponse],
  );
}

export default OtpLoginForm;
