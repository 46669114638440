// ----------------------------------------------------------------------

import { Theme } from '@mui/material/styles';
import { pxToRem } from '../../paletteFuncs';
//import { pxToRem } from '../../paletteFuncs';
export default function Typography(theme: Theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontSize: 23,
        },
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(2),
        },
        text: {
          lineHeight: 1.5,
          //fontSize: pxToRem(32),
        },
        body1: {
          // lineHeight: 1.5,
          // fontSize: pxToRem(32),
        },
        body2: {
          color: theme.palette.text.boby2,
          // lineHeight: 1.5,
          // fontSize: pxToRem(28),
        },
        caption: {
          fontSize: pxToRem(28),
        },
        caption_date: {
          // fontSize: pxToRem(28),
        },

        subtitle2: {
          color: theme.palette.grey[300],
          // lineHeight: 1.5,
          // fontSize: pxToRem(28),
        },
      },
    },
  };
}
