import React from 'react';
import { makeStyles } from '@mui/styles';
import Loading from '../Loading';

//import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
//import { getDoctorsTimeSlots } from '../../actions/visit';
import { useVisitState } from '../../context/VisitContext';
import { formatHL7DateToTime } from '../../helpers/dateFormat';
const useStyles = makeStyles((theme) => ({
  marginBottom: { marginBottom: theme.spacing(2) },
  buttonSlot: {
    margin: `0 ${theme.spacing(2)} ${theme.spacing(2)} 0`,
    minWidth: theme.spacing(9),
    padding: `${theme.spacing(1.2)} ${theme.spacing(1.8)}`,
  },
}));
import isEmpty from '../../helpers';
import { Alert, IconButton, useMediaQuery } from '@mui/material';
import { Carousel } from '@trendyol-js/react-carousel';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const DoctorSlots = ({ toggleModalCreateVisit }) => {
  //const { t } = useTranslation();
  const classes = useStyles();
  const {
    state: { selectedVisitKindId, doctor, timeSlots },
  } = useVisitState();
  //  console.log('timeSlots', timeSlots);

  // const visitDate = formatStrDateToHL7(selectedDate);
  // console.log('visitDate', visitDate);
  // useEffect(() => {
  //   if (selectedClinicId && selectedVisitKindId)
  //     getDoctorsTimeSlots({
  //       setState,
  //       date: visitDate,
  //       clinicId: selectedClinicId,
  //       doctorId: doctorId,
  //       plSubjId: doctor.plSubjId,
  //       visitKindId: selectedVisitKindId,
  //       cached: cachedTimeSlots,
  //       consultationType: isOnlineParam ? 'Online' : 'Offline',
  //     });

  //   // eslint-disable-next-line
  // }, [visitDate]);
  const Item = ({ slot }) => {
    return (
      <Button
        color="primary"
        variant="outlined"
        value={slot}
        onClick={(e) => {
          toggleModalCreateVisit(e.currentTarget.value);
        }}
        key={slot}
        className={classes.buttonSlot}
      >
        {formatHL7DateToTime(slot)}
      </Button>
    );
  };
  const md = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const sm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const show = sm ? 3 : md ? 5 : 4;
  //  const show = 3;
  // //console.log('md', md, 'sm', sm, 'show', show);
  return (
    <>
      {!doctor.isLoaded ? (
        <Loading />
      ) : doctor.responseError != null ? (
        <Alert severity="warning">
          <Typography variant="h6" component="p">
            {doctor.responseError}
          </Typography>
        </Alert>
      ) : !isEmpty(timeSlots) && selectedVisitKindId != null ? (
        <>
          {/* <Typography
            variant="h5"
            component="p"
            className={classes.marginBottom}
          >
            {t('VISIT.SLOTS')}
          </Typography> */}
          <Carousel
            show={show}
            slide={show - 1}
            swiping={true}
            rightArrow={
              <IconButton
                color="primary"
                aria-label="next slot"
                sx={{ ml: 1 }}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            }
            leftArrow={
              <IconButton
                color="primary"
                aria-label="previos slot"
                sx={{ mr: 1 }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
            }
          >
            {timeSlots.map((slot) => (
              <Item slot={slot} key={slot} />
            ))}
          </Carousel>
        </>
      ) : (
        <Alert severity="info">
          Слоты не найдены, попробуйте выбрать другие опции для
          получения слотов
        </Alert>
      )}
    </>
  );
};

export default DoctorSlots;
