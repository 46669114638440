import React from 'react';

import { VisitProvider } from '../../context/VisitContext';
import DoctorContainer from './DoctorContainer';

export default function Visit() {
  return (
    <VisitProvider>
      <DoctorContainer />
    </VisitProvider>
  );
}
