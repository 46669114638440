import React, { FC } from 'react';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/ru';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useLanguageValue } from '../../context/LanguageContext';
import { Stack } from '@mui/material';
import { mask } from '../../helpers/dateFormat';

interface MuiUIPickerProps {
  value: Dayjs | null;
  handleChange: (value: Dayjs | null) => void;
  label: string;
  inputFormat: string;
  disablePast?: boolean;
  disabled?: boolean;
  required?: boolean;
  errorText?: string | null;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  defaultCalendarMonth?: Dayjs;
  margin?: 'normal' | 'dense' | 'none' | undefined;
  preventKeyDown?: boolean;
}

const MuiUIPicker: FC<MuiUIPickerProps> = ({
  value,
  handleChange,
  label,
  inputFormat,
  disablePast,
  disabled,
  errorText,
  minDate,
  maxDate,
  defaultCalendarMonth,
  required,
  margin = 'none',
  preventKeyDown = false,
}) => {
  const {
    languageState: { language },
  } = useLanguageValue();
  //console.log('errorText', errorText);
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={language}
    >
      <Stack spacing={3}>
        <DesktopDatePicker
          disablePast={disablePast}
          disabled={disabled}
          label={label}
          mask={mask(inputFormat)}
          inputFormat={inputFormat}
          value={value}
          onChange={handleChange}
          minDate={minDate}
          maxDate={maxDate}
          defaultCalendarMonth={defaultCalendarMonth}
          renderInput={(params) => (
            <TextField
              {...params}
              name="date-picker"
              fullWidth
              margin={margin}
              onKeyDown={(e) => {
                if (preventKeyDown) e.preventDefault();
              }}
              error={errorText != null}
              helperText={errorText != null && errorText}
              required={required}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default MuiUIPicker;
