import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  CircularProgress,
  Collapse as Fade,
  TextField as Input,
  FormControlLabel,
  Checkbox,
  Grid,
  FormControl,
  MenuItem,
  InputLabel,
  // OutlinedInput,
  // FormHelperText,
  // IconButton,
  // InputAdornment,
  Select,
  Alert,
} from '@mui/material';

// icons
// import CheckIcon from '@mui/icons-material/Check';
// import CloseIcon from '@mui/icons-material/Close';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import VisibilityIcon from '@mui/icons-material/Visibility';

//components
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modale from '../../components/Modals/Modale';
//form func

import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
//i18n
import { useTranslation } from 'react-i18next';

import isEmpty from '../../helpers';
import moment from 'moment';

import { agreementText } from '../../actions/user';
import { useLanguageValue } from '../../context/LanguageContext';
import { useUserStateDispatch } from '../../context/UserContext';
import MuiUIPicker from '../MUIDatePicker';
import ConfirmForm from './ConfirmForm';

const useStyles = makeStyles((theme) => ({
  alignCenter: {
    textAlign: 'center',
  },
  submitButton: {
    height: 46,
    textTransform: 'none',
    minWidth: 200,
    width: '100%',
    marginTop: theme.spacing(1),
  },
  errorMessage: {
    //textAlign: 'center',
    marginBottom: theme.spacing(2),
    width: '100%',
  },

  agreementButton: {
    textAlign: 'left',
    textTransform: 'none',
    fontWeight: 500,
  },

  loginLoader: {
    marginLeft: theme.spacing(4),
  },

  passwordSuccess: {
    color: theme.palette.success.main,
  },
  passwordError: {
    color: theme.palette.warning.error,
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

function SignUpForm({
  values,
  setValues,
  errors,
  handleChange,
  serverResponse,
  isLoading,
  setIsLoading,
  setServerResponse,
  handleSubmit,
  handleDateChange,
  handleChangeGender,
  isConfirmForm,
  getNewCode,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    languageState: { language },
  } = useLanguageValue();
  const {
    userState: { appInfo },
  } = useUserStateDispatch();

  const [openModal, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!openModal);
  };

  // get agreement
  const [agreement, setAgreement] = useState('');

  useEffect(() => {
    agreementText(setAgreement, setIsLoading, setServerResponse);
  }, []);

  const handleAgree = (e) => {
    console.log('e.target.checked', e.target.checked);
    const vals = {
      ...values,

      agreement: e.target.checked,
    };
    setValues(vals);
  };

  const disabled =
    values.login == null ||
    values.firstName == null ||
    values.lastName == null ||
    values.birthDate == null ||
    !values.agreement ||
    !isEmpty(errors);

  console.log('values', values);

  return (
    <>
      <Fade
        styte={{ width: '100%' }}
        in={serverResponse?.action != null}
        style={
          serverResponse?.action != null
            ? { display: 'flex' }
            : { display: 'none' }
        }
      >
        <Alert
          severity={
            serverResponse?.action === 'OK' ? 'success' : 'warning'
          }
          className={classes.errorMessage}
        >
          <Typography variant="body2">
            {serverResponse?.action === 'OK'
              ? t('SIGN.UP_OK')
              : serverResponse?.action === 'CONFIRM_CODE_SENT' &&
                serverResponse?.deliveryMethod === 'Email'
              ? t('SIGN.CH_CONFIRM_CODE_SENT_EMAIL')
              : serverResponse?.action === 'CONFIRM_CODE_SENT' &&
                serverResponse?.deliveryMethod === 'Sms'
              ? t('SIGN.CH_CONFIRM_CODE_SENT_SMS')
              : serverResponse?.action === 'CONFIRM_CODE_SENT' &&
                serverResponse?.deliveryMethod === 'VoiceCall'
              ? t('SIGN.CH_CONFIRM_CODE_SENT_VOICE')
              : serverResponse?.action === 'WRONG_LOGIN_OR_PASSWORD'
              ? t('SIGN.UP_WRONG_LOG_OR_PASS')
              : serverResponse?.action === 'EMAIL_ALREADY_REGISTERED'
              ? t('SIGN.UP_EMAIL_ALREADY_REG')
              : serverResponse?.action === 'MAY_LOGIN'
              ? t('SIGN.UP_MAY_LOGIN')
              : serverResponse?.action === 'WRONG_CONFIRMATION_CODE'
              ? t('SIGN.UP_CONFIRM_CODE_WRONG')
              : serverResponse?.text != null
              ? serverResponse?.text
              : serverResponse?.action}
          </Typography>
        </Alert>
      </Fade>
      {isConfirmForm ? (
        <ConfirmForm
          values={values}
          errors={errors}
          handleChange={handleChange}
          isLoading={isLoading}
          fieldName="confirmationCode"
          handleSubmit={handleSubmit}
          getNewCode={getNewCode}
          timerNumder={
            serverResponse?.lifeTime != null
              ? serverResponse?.lifeTime
              : 300
          }
        />
      ) : (
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          <Grid item xs={12}>
            <Input
              name="lastName"
              variant="outlined"
              autoComplete={'off'}
              value={values.lastName || ''}
              onChange={handleChange}
              label={t('COMPONENT.FORM_LAST_NAME')}
              type="text"
              fullWidth
              inputProps={{ maxLength: 50 }}
              required
              error={errors?.lastName != null}
              helperText={
                errors?.lastName != null && errors?.lastName
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="firstName"
              variant="outlined"
              autoComplete={'off'}
              value={values.firstName || ''}
              onChange={handleChange}
              label={t('COMPONENT.FORM_NAME')}
              type="text"
              fullWidth
              inputProps={{ maxLength: 50 }}
              required
              error={errors?.firstName != null}
              helperText={
                errors?.firstName != null && errors?.firstName
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="middleName"
              variant="outlined"
              autoComplete={'off'}
              value={values.middleName || ''}
              onChange={handleChange}
              label={t('COMPONENT.FORM_MIDLE_NAME')}
              type="text"
              fullWidth
              inputProps={{ maxLength: 50 }}
              error={errors?.middleName != null}
              helperText={
                errors?.middleName != null && errors?.middleName
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MuiUIPicker
              inputFormat={
                language === 'ru' ? 'DD.MM.YYYY' : 'MM/DD/YYYY'
              }
              //id="date-picker-inline"
              label={t('COMPONENT.BIRTH_DATE')}
              value={
                values?.birthDate != null ? values?.birthDate : null
              }
              handleChange={handleDateChange}
              defaultCalendarMonth={moment().add(
                appInfo?.ageLimitHigh,
                'years',
              )}
              minDate={moment().add(appInfo?.ageLimitLow, 'years')}
              maxDate={moment().add(appInfo?.ageLimitHigh, 'years')} //maxDate
              // invalidDateMessage={t('COMPONENT.INVALID_BIRTH_DATE')}
              // minDateMessage={t('COMPONENT.INVALID_BIRTH_DATE')}
              // maxDateMessage={t('COMPONENT.INVALID_BIRTH_DATE')}

              required
              errorText={errors?.birthDate}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                {t('COMPONENT.FORM_GENDER')}
              </InputLabel>
              <Select
                //labelId="demo-simple-select-outlined-label"
                //id="demo-simple-select-outlined"
                name="gender"
                value={values?.gender != null ? values?.gender : ''}
                onChange={handleChangeGender}
                label={t('COMPONENT.FORM_GENDER')}
              >
                <MenuItem value="M">
                  {t('COMPONENT.FORM_GENDER_M')}
                </MenuItem>
                <MenuItem value="F">
                  {t('COMPONENT.FORM_GENDER_F')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Modale
              open={openModal}
              toggleModal={toggleModal}
              title={t('SIGN.UP_TITLE_LIC_AGR')}
              my={3}
            >
              <ReactMarkdown
                remarkPlugins={[remarkBreaks]}
                rehypePlugins={[rehypeRaw]}
              >
                {agreement}
              </ReactMarkdown>
            </Modale>

            <FormControlLabel
              control={
                <Checkbox
                  sx={(theme) => ({
                    '&.Mui-checked': {
                      color: theme.palette.primary.main,
                    },
                    '& .MuiSvgIcon-root': { fontSize: 32 },
                  })}
                  name="agreement"
                  onChange={handleAgree}
                  defaultChecked={true}
                  color="primary"
                />
              }
              label={
                <Typography variant="body1">
                  Я принимаю{' '}
                  <Button
                    color="primary"
                    variant="text"
                    className={classes.agreementButton}
                    onClick={toggleModal}
                    style={{ padding: 0 }}
                  >
                    условия соглашения
                  </Button>{' '}
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            {isLoading ? (
              <CircularProgress
                size={26}
                className={classes.loginLoader}
              />
            ) : (
              <Button
                disabled={disabled}
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                fullWidth
                className={classes.submitButton}
              >
                {t('SIGN.UP_BUTTON_SUBMIT')}
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default SignUpForm;
