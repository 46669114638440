import React from 'react';

// import { useTranslation } from 'react-i18next';
// import { Box, Button, Typography } from '@mui/material';

import OtpLoginForm from '../../components/Forms/OtpLoginForm';
import { useUserStateDispatch } from '../../context/UserContext';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function OptLogin({
  //setActiveTabId,
  serverResponse,
  //setServerResponse,
  values,
  errors,
  handleChange,
  handleSubmit,
  setValues,
  setErrors,
  isLoading,
  validate,
  isConfirmForm,
  getNewCode,
}) {
  const {
    userState: { appInfo },
  } = useUserStateDispatch();

  // React.useEffect(() => {
  //   if (values.login == null) {
  //     const vals = {
  //       ...values,
  //       login: '79262223155',
  //     };
  //     setValues(vals);
  //   }

  //   // eslint-disable-next-line
  // }, []);

  const handlePhoneChange = (e) => {
    const vals = {
      ...values,
      login: e.target.value,
    };

    setValues(vals);
    setErrors(validate(vals, appInfo));
  };

  return (
    <OtpLoginForm
      values={values}
      errors={errors}
      serverResponse={serverResponse}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      isLoading={isLoading}
      isConfirmForm={isConfirmForm}
      handlePhoneChange={handlePhoneChange}
      phoneMask={appInfo.phoneMask}
      getNewCode={getNewCode}
    />
  );
}

export default OptLogin;
