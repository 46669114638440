import i18next from 'i18next';
import {
  isValidEmail,
  isValidPhone,
  notAvalibleBirthDate,
  rexFio,
} from '../../helpers/validators';

export function validate(values, appInfo) {
  let errors = {};
  if (values.birthDate != null && !values.birthDate) {
    errors.birthDate = i18next.t('COMPONENT.EMPTY_BIRTHDAY');
  } else if (
    values.birthDate != null &&
    notAvalibleBirthDate(values.birthDate, [
      appInfo?.ageLimitLow,
      appInfo?.ageLimitHigh,
    ])
  ) {
    errors.birthDate = i18next.t('COMPONENT.INVALID_BIRTH_DATE');
  }

  /**/

  if (
    appInfo?.authIdentifiers === 'email' &&
    values.login != null &&
    !isValidEmail(values.login)
  ) {
    errors.login = i18next.t('SIGN.UP_NOT_VALID_EMAIL');
  } else if (
    appInfo?.authIdentifiers === 'phone' &&
    values.login != null &&
    !isValidPhone(values.login, appInfo.countryCode)
  ) {
    errors.login = i18next.t('SIGN.UP_NOT_VALID_PHONE');
  } else if (
    values.login != null &&
    !isValidEmail(values.login) &&
    !isValidPhone(values.login, appInfo.countryCode)
  ) {
    errors.login = i18next.t('SIGN.UP_NOT_VALID_EMAIL_OR_PHONE');
  }

  // if (values.password != null) {
  //   /*
  // /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[,.!@#$%^&*_-]).{5,}$/
  // Пояснение:
  // (?=.*[0-9]) - строка содержит хотя бы одно число;
  // (?=.*[a-z]) - строка содержит хотя бы одну латинскую букву в нижнем регистре;
  // (?=.*[A-Z]) - строка содержит хотя бы одну латинскую букву в верхнем регистре;
  // (?=.*[,.!@#$%^&*_-]) - строка содержит хотя бы один символ
  // .{5,} - строка состоит не менее, чем из 5 вышеупомянутых символов.
  // */

  //   const checkRules = {
  //     ONE_DIGIT: /(?=.*[0-9])/,
  //     ONE_UPPER_LETTER: /(?=.*[A-Z])/,
  //     ONE_LOWECASE_LAEER: /(?=.*[a-z])/,
  //     ONE_SYMBOL: /(?=.*[,.!@#$%^&*_-])/,
  //     MIN_LENGTH: '.{5,}',
  //   };

  //   Object.keys(checkRules).forEach((key) => {
  //     const regex = new RegExp(checkRules[key]);
  //     errors = {
  //       ...errors,
  //       passRules: {
  //         ...errors?.passRules,
  //         [key]: {
  //           valid: regex.test(values.password),
  //           ruleText: i18next.t(`SIGN.UP_${key}`),
  //         },
  //       },
  //     };
  //   });

  //   if (
  //     Object.keys(errors?.passRules).filter(
  //       (key) => !errors?.passRules[key].valid,
  //     ).length === 0
  //   ) {
  //     delete errors?.passRules;
  //   } else {
  //     errors.password = i18next.t('SIGN.UP_NOT_VALID_PASS');
  //   }
  // }
  // if (
  //   values.repassword != null &&
  //   values.password !== values.repassword
  // ) {
  //   errors.repassword = i18next.t('SIGN.UP_NOT_MATCH_PASS');
  // }

  if (values.firstName != null && !values.firstName) {
    errors.firstName = i18next.t('COMPONENT.EMPTY_NAME');
  }
  if (values.lastName != null && !values.lastName) {
    errors.lastName = i18next.t('COMPONENT.EMPTY_LAST_NAME');
  }

  if (values.firstName != null && !rexFio.test(values.firstName)) {
    errors.firstName = i18next.t('SIGN.UP_NOT_VALID_NAME');
  }

  if (values.lastName != null && !rexFio.test(values.lastName)) {
    errors.lastName = i18next.t('SIGN.UP_NOT_VALID_LAST_NAME');
  }

  if (
    values.firstName != null &&
    values.lastName != null &&
    values.firstName.trim() === values.lastName.trim()
  ) {
    errors.firstName = i18next.t('SIGN.UP_CANNOT_BE_SAME');
    errors.lastName = i18next.t('SIGN.UP_CANNOT_BE_SAME');
  }

  if (values.middleName && !rexFio.test(values.middleName)) {
    errors.middleName = i18next.t('SIGN.UP_NOT_VALID_MIDDLE_NAME');
  }
  //console.log('== errors', errors);
  return errors;
}
