import { TypographyOptions } from '@mui/material/styles/createTypography';
//import { pxToRem } from '../paletteFuncs';
//import { pxToRem } from '../paletteFuncs';

const FONT_PRIMARY = 'PF_Encore_Sans_Pro';

const typography: TypographyOptions = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,

  subtitle1: {
    fontWeight: 400,
    lineHeight: 1.6,
    fontSize: 18,
  },
  subtitle2: {
    fontWeight: 450,
    lineHeight: 1.5,
    fontSize: 16,
  },
  body1: {
    lineHeight: 'normal',
    fontSize: 14,
  },
  body2: {
    lineHeight: 'normal',
    fontSize: 14,
  },
  caption: {
    //lineHeight: 1.5,
    //fontSize: pxToRem(10),
  },

  button: {
    //fontWeight: 500,
    lineHeight: 1.1,
    fontSize: 16,
    //    textTransform: 'capitalize',
  },
};

export default typography;
