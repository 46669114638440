const AUTH_ROUTE_PREFIX = 'app';
const version = process.env.REACT_APP_VERSION;
const theme = process.env.REACT_APP_THEME;

const defLang = process.env.REACT_APP_DEF_LANG;

const baseUrl = `https://${process.env.REACT_APP_BASEHOST}/${process.env.REACT_APP_PATH_SERVICE}`;
const baseURLApi = `${baseUrl}/`;
const APP_CODE = process.env.REACT_APP_APP_CODE;

const defLoginPassword = {
  login: process.env.REACT_APP_LOGIN,
  password: process.env.REACT_APP_PASS,
};

const nodeEnv = process.env.NODE_ENV;

console.log(
  ' ===== vars ===== \n',
  '\n process.env.NODE_ENV',
  nodeEnv,
  '\n baseUrl',
  baseUrl,
  '\n defLang',
  defLang,
  '\n baseHostChat',
  APP_CODE,
  '\n version',
  version,
);

const secretKey =
  'tOMcNH4Syn6dxprhk4o6qxJB5hHoKIUdxwEUEfOi3X3mIue1qcFkxowyQa6t1QaGTYMopgzskALEMixLmTE5Ti';

const transLang = (lang) =>
  lang === 'ru'
    ? 'rus'
    : lang === 'fr'
    ? 'fra'
    : lang === 'en'
    ? 'eng'
    : null;

const langCode = (_lang) => {
  if (_lang != null && ['ru', 'en', 'fr'].includes(_lang))
    return transLang(_lang);
  return transLang(defLang);
};

const config = {
  nodeEnv,

  defLang,
  baseUrl,
  baseURLApi,

  APP_CODE,
  secretKey,
  langCode,
  defLoginPassword,

  AUTH_ROUTE_PREFIX,
  version,
  theme,
};
export default config;
