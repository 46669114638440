import md5 from 'md5';

export const toUpperCaseFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const toLowerCaseFirstLetter = (str) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const transformKeysInData = (data, transformFunc) => {
  if (data === null) return null;

  if (Array.isArray(data))
    return data.map((item) =>
      transformKeysInData(item, transformFunc),
    );

  if (typeof data === 'object')
    return Object.keys(data).reduce((result, key) => {
      result[transformFunc(key)] = transformKeysInData(
        data[key],
        transformFunc,
      );
      return result;
    }, {});

  return data;
};

export const generateSign = (data, secretKey) => {
  const params = Object.keys(data)
    .sort()
    .filter((key) => key !== 'authToken')
    .map((key) => data[key])
    .filter(
      (value) => typeof value !== 'object' && value !== undefined,
    )
    .map((value) =>
      typeof value === 'boolean'
        ? toUpperCaseFirstLetter(value.toString())
        : value,
    );

  return md5(`${params.join('')}${secretKey}`).toString();
};

export const passMd5 = (pass) => md5(pass);

export const cleanPhoneValue = (value) => {
  if (value == null) return '';
  // удаляем разрешенные символы
  const cleanValue = value.replace(/[\s+()-]/gi, '');
  const numbers = cleanValue.replace(/[^\d]/gi, '');
  return numbers;
};

export const getParam = (param) => {
  const QueryString = window.location.search;
  const urlParams = new URLSearchParams(QueryString);
  return urlParams.get(param);
};
