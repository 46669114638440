import gmseco from './gmseco';
import gmsclinic from './gmsclinic';
import gmshospital from './gmshospital';
export default function getTheme(name?: string) {
  console.log('name', name);
  return name === 'gmseco'
    ? gmseco
    : name === 'gmshospital'
    ? gmshospital
    : gmsclinic;
}
