import React from 'react';
import { itemId, itemIdVisitKind } from '../actions/visit';
import { useUserStateDispatch } from './UserContext';
import isEmpty, { arrayUniqueByKey } from '../helpers';
import { getParam } from '../helpers/utils';
export const DEFAULT_COUNT_SIZE = 15;
const VisitContext = React.createContext();

const defaultDate = () => {
  const today = new Date();
  // const hoursNow = today.getHours();
  // const tomorrow = new Date(
  //   today.getTime() + 24 * 60 * 60 * 1000 * 3,
  // );
  // return hoursNow <= 20 ? today : tomorrow;

  return today;
};
//console.log('defaultDate', defaultDate());
const clinicsDoctor = (data, allClinics) => {
  const dataClinicsIds = data.map((it) => it.doctor.clinicId);
  return allClinics.filter((it) => dataClinicsIds.includes(it.id));
};

const specsFromVisitKinds = (data, selectedClinicId) => {
  let specs = [];
  data
    .filter(
      (item) =>
        item.doctor.clinicId === selectedClinicId ||
        selectedClinicId == null,
    )
    .forEach((item) => {
      specs = arrayUniqueByKey(
        [
          ...specs,
          ...item.visitKinds.map((it) => ({
            clinicId: item.doctor.clinicId,
            id: it.specializationId,
            name: it.specializationName,
          })),
        ],
        'id',
      );
    });
  return specs;
};

const specsFromDoctor = (data, selectedClinicId) => {
  //console.log('data', data);
  return arrayUniqueByKey(
    [
      ...data
        .filter(
          (item) =>
            item.doctor.clinicId === selectedClinicId ||
            selectedClinicId === null,
        )
        .map((item) => ({
          clinicId: item.doctor.clinicId,
          id: item.doctor.specializationId,
          name: item.doctor.specializationInfo,
        })),
    ],
    'id',
  );
};

const getVisitKinds = (
  data,
  selectedSpecId,
  isSpecsFromVisitKinds,
) => {
  let visitKinds = [];
  if (!isEmpty(data) && isSpecsFromVisitKinds)
    data.forEach((item) => {
      if (!isEmpty(item))
        visitKinds = arrayUniqueByKey(
          [
            ...visitKinds,
            ...(item?.visitKinds || [])
              .filter(
                (it) =>
                  it.specializationId === selectedSpecId ||
                  selectedSpecId == null,
              )
              .map((it) => ({
                clinicId: item.doctor.clinicId,
                specializationId: it.specializationId,
                id: it.id,
                name: it.name,
                itemId: itemIdVisitKind(it),
              })),
          ],
          'itemId',
        );
    });
  if (!isEmpty(data) && !isSpecsFromVisitKinds) {
    data = data.filter(
      (item) =>
        item.doctor.specializationId === selectedSpecId ||
        selectedSpecId == null,
    );

    data.forEach((item) => {
      if (!isEmpty(item))
        visitKinds = arrayUniqueByKey(
          [
            ...visitKinds,
            ...(item?.visitKinds || []).map((it) => ({
              clinicId: item.doctor.clinicId,
              specializationId: it.specializationId,
              id: it.id,
              name: it.name,
              itemId: itemIdVisitKind(it),
            })),
          ],
          'itemId',
        );
    });
  }
  return visitKinds;
};

const visitData = (data, selectedClinicId, selectedSpecId) => {
  let dataOne = data.find(
    (it) =>
      it.doctor.clinicId === selectedClinicId &&
      it.doctor.specializationId === selectedSpecId,
  );

  if (selectedClinicId == null && selectedSpecId != null)
    dataOne = data.find(
      (it) => it.doctor.specializationId === selectedSpecId,
    );

  if (selectedClinicId != null && selectedSpecId == null)
    dataOne = data.find(
      (it) => it.doctor.clinicId === selectedClinicId,
    );

  if (selectedClinicId == null && selectedSpecId == null)
    dataOne = data[0];

  return {
    timeSlotsVisitKindId: dataOne?.timeSlotsVisitKindId,
    timeSlots: dataOne?.timeSlots,
    doctor: dataOne?.doctor,
  };
};

const defaultSpec = (
  data,
  selectedClinicId,
  isSpecsFromVisitKinds,
) => {
  if (selectedClinicId != null && !isSpecsFromVisitKinds)
    return data.find((it) => it.doctor.clinicId === selectedClinicId)
      ?.doctor.specializationId;

  return isSpecsFromVisitKinds
    ? data[0].visitKinds[0].specializationId
    : data[0]?.doctor.specializationId;
};

const defaultVisitKind = (data, selectedSpecId) => {
  let defaultVisitKind = null;
  if (selectedSpecId != null)
    data.forEach((itdata) => {
      defaultVisitKind = itdata.visitKinds.find(
        (it) => it.specializationId === selectedSpecId,
      )?.id;
      if (defaultVisitKind != null) return;
    });
  else defaultVisitKind = data[0].visitKinds[0].id;

  return defaultVisitKind;
};

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'DOCTOR_LOADING':
      return {
        ...state,
        doctor: {
          ...state.doctor,
          isLoaded: false,
        },
      };

    case 'SET_CLINIC': {
      const selectedSpecId = defaultSpec(
        state.doctor.data,
        action.payload,
        state.isSpecsFromVisitKinds,
      );

      const { timeSlotsVisitKindId, timeSlots, doctor } = visitData(
        state.doctor.data,
        action.payload,
        selectedSpecId,
      );

      return {
        ...state,
        selectedClinicId: action.payload,
        selectedSpecId,
        selectedVisitKindId: timeSlotsVisitKindId,
        timeSlots,
        doctor: {
          ...state.doctor,
          ...doctor,
          visitKinds: [],
          specs: state.isSpecsFromVisitKinds
            ? specsFromVisitKinds(state.doctor.data, action.payload)
            : specsFromDoctor(state.doctor.data, action.payload),
        },
      };
    }
    case 'SET_SPEC': {
      const { timeSlotsVisitKindId, timeSlots, doctor } = visitData(
        state.doctor.data,
        state.selectedClinicId,
        action.payload,
      );
      const selectedVisitKindId =
        defaultVisitKind(state.doctor.data, action.payload) ||
        timeSlotsVisitKindId;
      return {
        ...state,
        selectedSpecId: action.payload,
        selectedVisitKindId,
        timeSlots,
        doctor: {
          ...state.doctor,
          ...doctor,
          visitKinds: getVisitKinds(
            state.doctor.data,
            action.payload,
            state.isSpecsFromVisitKinds,
          ),
        },
      };
    }

    case 'SET_VISIT_KIND':
      return {
        ...state,
        selectedVisitKindId: action.payload,
      };
    case 'SET_SELECT_DATE':
      return {
        ...state,
        selectedDate: action.payload,
      };
    case 'SET_VISIT_DATE':
      return {
        ...state,
        visitDate: action.payload,
      };

    case 'SET_FREE_DATES':
      return {
        ...state,
        freeDates: action.payload,
      };

    case 'FETCH_DOCTOR': {
      //console.log('--- action.payload.data', action.payload.data);
      if (isEmpty(action.payload.data)) {
        return {
          ...state,
          doctor: {
            ...state.doctor,
            isLoaded: true,
          },
        };
      }
      const clinics = clinicsDoctor(
        action.payload.data,
        action.payload.clinics,
      );
      const selectedClinicId =
        state.selectedClinicId ||
        action.payload.data[0]?.doctor.clinicId;

      const selectedSpecId =
        state.selectedSpecId ||
        defaultSpec(
          action.payload.data,
          selectedClinicId,
          state.isSpecsFromVisitKinds,
        );

      const { timeSlotsVisitKindId, timeSlots, doctor } = visitData(
        action.payload.data,
        selectedClinicId,
        selectedSpecId,
      );
      const selectedVisitKindId =
        state.selectedVisitKindId ||
        defaultVisitKind(action.payload.data, selectedSpecId) ||
        timeSlotsVisitKindId;
      return {
        ...state,
        selectedClinicId,
        selectedSpecId,
        selectedVisitKindId,
        timeSlots,
        doctor: {
          ...doctor,
          clinics,
          specs: state.isSpecsFromVisitKinds
            ? specsFromVisitKinds(
                action.payload.data,
                selectedClinicId,
              )
            : specsFromDoctor(action.payload.data, selectedClinicId),
          visitKinds: getVisitKinds(
            action.payload.data,
            selectedSpecId,
            state.isSpecsFromVisitKinds,
          ),
          data: [
            ...action.payload.data.map((item) => ({
              ...item,
              itemId: itemId(item),
            })),
          ],
          isLoaded: true,
        },
      };
    }
    case 'FETCH_DOCTOR_TIME_SLOT':
      return {
        ...state,
        doctor: {
          ...state.doctor,
          isLoaded: true,
        },
        timeSlots: action.payload,
      };
    case 'FETCH_DOCTOR_ERROR':
      return {
        ...state,
        doctor: {
          ...state.doctor,
          isLoaded: true,
          responseError: action.payload,
        },
      };
    case 'ADD_VISIT_LOADING':
      localStorage.removeItem('visitData');
      return {
        ...state,
        visit: {
          ...state.visit,
          isLoaded: false,
        },
      };
    case 'ADD_VISIT_SERVER_ERR':
      return {
        ...state,
        visit: {
          ...state.visit,
          serverError: action.payload,
          isLoaded: true,
        },
      };

    case 'RESET_VISIT':
      return {
        ...state,
        visit: {
          data: null,
          isLoaded: true,
          serverError: null,
        },
      };
    case 'ADD_VISIT':
      return {
        ...state,
        visit: {
          ...state.visit,
          ...action.payload,
          isLoaded: true,
        },
      };

    default:
      return { ...state };
  }
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

const VisitProvider = ({ children }) => {
  const {
    userState: { appInfo },
  } = useUserStateDispatch();
  console.log('window.DOCTOR_ID', window.DOCTOR_ID);
  const doctorId = getParam('doctorIdParam')
    ? getParam('doctorIdParam')
    : window.DOCTOR_ID != null
    ? window.DOCTOR_ID
    : null;
  //const doctorId = 222420;
  const [state, setState] = React.useReducer(rootReducer, {
    doctorId,
    doctor: {
      isLoaded: false,
      data: [],
      specs: [],
      clinics: [],
      visitKinds: [],
      responseError: null,
    },
    visit: {
      isLoaded: true,
      data: null,
      serverError: null,
    },
    isSpecsFromVisitKinds: appInfo?.usePlExGrWebSpecializations,
    selectedClinicId: null,
    selectedDate: defaultDate(),
    selectedSpecId: null,
    selectedVisitKindId: null,
    freeDates: [],
    isOnlineParam: false,
    cachedTimeSlots: true,
    filterWithPlanningOnly: false,
    filterWithTimeSlotsOnly: appInfo?.filterWithTimeSlotsOnly,
    sortOrder: true,
  });
  //  console.log('appInfo', appInfo);

  return (
    <VisitContext.Provider value={{ state, setState }}>
      {children}
    </VisitContext.Provider>
  );
};

const useVisitState = () => {
  const context = React.useContext(VisitContext);

  return context;
};

export { VisitProvider, VisitContext, useVisitState };
