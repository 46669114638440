import React from 'react';
import InitApp from './InitApp';
import Visit from '../pages/visit';

export default function App() {
  return (
    <>
      <InitApp />
      <Visit />
    </>
  );
}
