import React, { useState } from 'react';

//import { useVisitState } from '../../context/VisitContext';
import { Alert, Button, Grid, Typography } from '@mui/material';
import Clinics from '../../components/FormComponents/SelectClinics';
import Specs from '../../components/FormComponents/SelectSpecs';
import SelectVisitKinds from '../../components/FormComponents/SelectVisitKinds';
import DoctorCalendar from '../../components/FormComponents/DoctorCalendar';
import DoctorSlots from '../../components/FormComponents/DoctorSlots';
import ModaleCreateVisit from './ModaleCreateVisit';
import { useVisitState } from '../../context/VisitContext';
import Loading from '../../components/Loading';
import {
  formatHL7DateToSrtDate,
  formatHL7DateToTime,
} from '../../helpers/dateFormat';
import { useTranslation } from 'react-i18next';
import User from '../../components/User';
import isEmpty from '../../helpers';
import AlertDialogSlide from '../../components/Modals/AlertDialogSlide';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function DoctorCard() {
  const { t } = useTranslation();

  const {
    state: {
      // doctorId,
      doctor: { visitKinds },
      // selectedDate,
      // selectedClinicId,
      // selectedSpecId,
      selectedVisitKindId,
      // isOnlineParam,
      // filterWithTimeSlotsOnly,
      // cachedTimeSlots,
      // filterWithPlanningOnly,
      visit,
    },
    setState,
  } = useVisitState();

  const [modalCreateVisit, setModalCreateVisit] = useState(false);
  const toggleModalCreateVisit = (visitDate) => {
    if (!modalCreateVisit) {
      setState({
        type: 'SET_VISIT_DATE',
        payload: visitDate,
      });
    } else {
      setState({
        type: 'SET_VISIT_DATE',
        payload: null,
      });
    }

    setModalCreateVisit(!modalCreateVisit);
  };

  return !visit.isLoaded ? (
    <Loading />
  ) : visit.serverError != null ? (
    <Alert severity="warning">
      <Typography variant="h6" component="p" mb={4}>
        {visit.serverError}
      </Typography>
      <Button
        variant="contained"
        onClick={() =>
          setState({
            type: 'RESET_VISIT',
            payload: null,
          })
        }
      >
        {t('COMPONENT.BACK_ONE_STEP')}
      </Button>
    </Alert>
  ) : visit.data == null ? (
    <>
      <User />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Clinics />
        </Grid>
        <Grid item xs={12}>
          <Specs />
        </Grid>
        {!isEmpty(visitKinds) && (
          <Grid item xs={12}>
            <SelectVisitKinds />
          </Grid>
        )}

        {selectedVisitKindId && (
          <Grid item xs={12} style={{ paddingTop: 12 }}>
            <DoctorCalendar />

            <DoctorSlots
              toggleModalCreateVisit={toggleModalCreateVisit}
            />
          </Grid>
        )}
      </Grid>
      <ModaleCreateVisit
        modalCreateVisit={modalCreateVisit}
        toggleModalCreateVisit={toggleModalCreateVisit}
      />
    </>
  ) : visit.data ? (
    <AlertDialogSlide
      isOpen
      contentTextOk={t('VISIT.ALERT_OK')}
      contentTextOkDetail={`${formatHL7DateToSrtDate(
        visit.visitDate,
      )} ${
        formatHL7DateToTime(visit.visitDate) !== '00:00'
          ? formatHL7DateToTime(visit.visitDate)
          : ''
      }`}
      severity="success"
      onCloseAlert={() =>
        setState({
          type: 'RESET_VISIT',
          payload: null,
        })
      }
    />
  ) : null;
}
