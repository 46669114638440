// ----------------------------------------------------------------------

import { Theme } from '@mui/material/styles';

export default function Typography(theme: Theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(2),
        },
        body1: {
          fontSize: 16,
          fontWeight: 500,
        },
        body2: {
          fontSize: 16,

          color: theme.palette.text.boby2,
        },
        caption: {
          fontSize: 14,
        },
        caption_date: {
          fontSize: 13,
        },

        subtitle2: {
          color: theme.palette.text.subtitle2,
        },
        h4: {
          fontWeight: 500,
          fontSize: 32,
        },
        h5: {
          fontWeight: 500,
          fontSize: 30,
        },
        h6: {
          fontWeight: 500,
          fontSize: 16,
        },
      },
    },
  };
}
