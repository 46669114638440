import axios from 'axios';
import config from '../config';
import isEmpty, { ifEmptyArr } from '../helpers';
import httpClient, { getError } from '../api/httpClient';
import api from '../api/apiParams';
//import { getParam } from '../helpers/utils';
import moment from 'moment';
import { isValidEmail, isValidPhone } from '../helpers/validators';

export const accountRole = {
  unknown: 0,
  patient: 1,
  agent: 2,
  doctor: 3,
  operator: 4,
};
export const savedEntites = ['clinics', 'appInfo'];
export async function getQrKey(setData) {
  try {
    const { data } = await httpClient.post(api.url('GetQrKey'));
    setData({ qrLoaded: true, ...data });
  } catch (err) {
    console.log('Err getQrKey ', err);
  }
}
export function afterReloadPage(
  isAuthenticated,
  role,
  lang,
  userDispatch,
) {
  /** check if page has been reloaded  */

  const pageAccessedByReload =
    (window.performance.navigation &&
      window.performance.navigation.type === 1) ||
    window.performance
      .getEntriesByType('navigation')
      .map((nav) => nav.type)
      .includes('reload');

  if (pageAccessedByReload) {
    console.log(
      '== ** RELOAD user data after F5 ** ==',
      isAuthenticated,
    );

    if (isAuthenticated) {
      getUserData(
        userDispatch,
        lang,

        false,
        null,
        null,
        role,
      );
    }
  }
}
export async function getQrState(
  key,
  dispatch,
  lang,
  navigate,
  setError,
  setIsLoading,
) {
  try {
    const { data } = await httpClient.get(api.url('GetQrState'), {
      key,
      isIssuer: false,
    });
    if (data?.status === 'success') {
      localStorage.setItem('authToken', data?.authToken);
      localStorage.setItem('refreshToken', data?.refreshToken);
      setIsLoading(true);
      getUserData(dispatch, lang, true, setError, setIsLoading);
    }
  } catch (err) {
    setIsLoading(false);
    setError(getError(err));
    console.log('Err getQrKey ', err);
  }
}

export async function getVersion(setVersion, old) {
  const path = config.nodeEnv === 'development' ? '' : 'static/js';
  const url = `${path}/version.json`;
  try {
    const { data } = await axios.get(
      `${url}?time=${moment().format('YYYYMMDD-HH:mm:ss:SSS')}`,
    );

    setVersion({ old, new: data.hash });
  } catch (err) {
    console.log('Err version ', err);
  }
}

export async function showcaseInfo(
  reqData,
  setCaseInfo,
  setIsLoading,
  lang,
) {
  setIsLoading(true);
  await httpClient
    .get(
      api.url('ShowcaseInfo'),
      {
        ...reqData,
      },
      {
        'app-сode': config.APP_CODE,
        'lang-code': config.langCode(lang),
      },
    )
    .then(async ({ data }) => {
      console.log('Console log  ');
      setIsLoading(false);
      setCaseInfo(data);
    })
    .catch(async (err) => {
      console.log('Err ShowcaseInfo ', getError(err));
      setIsLoading(false);
      //await setError(getError(err));
    });
}
export async function brandLogoImage(brandId, setBrand) {
  await httpClient
    .get(api.url('GetBrandLogoImage'), {
      brandId,
    })
    .then(async ({ data }) => {
      console.log('GetBrandLogoImage', data);
      setBrand(data);
    })
    .catch(async (err) => {
      console.log('Err GetBrandLogoImage ', getError(err));
    });
}

export async function agreementText(
  setAgreement,
  setIsLoading,
  //  setError,
) {
  setIsLoading(true);
  await httpClient
    .get(
      api.url('GetAppLicenseAgreementText'),
      {
        platform: 'web',
      },
      { 'app-сode': config.APP_CODE },
    )
    .then(({ data }) => {
      setIsLoading(false);
      setAgreement(data?.text);
    })
    .catch((err) => {
      console.log('Err GetAppLicenseAgreementText ', getError(err));
      setIsLoading(false);
      // setError(getError(err));
    });
}

export async function singInUpConfirmationCode(
  values,
  setIsLoading,
  setServerResponse,
  dispatch,
  language,
) {
  setIsLoading(true);
  const login = {};
  login['login'] = values.login;
  const body = {
    ...login,
    password: values.password,
    confirmationCode: values.confirmationCode,
  };
  await httpClient
    .post(api.url('ConfirmNewAccount'), body, {
      config: { isWrap: false },
    })
    .then(({ data }) => {
      if (!isEmpty(data)) {
        localStorage.setItem('authToken', data?.authToken);
        localStorage.setItem('refreshToken', data?.refreshToken);
        getUserData(
          dispatch,
          language,
          true,
          setServerResponse,
          setIsLoading,
          data?.role,
        );
      }
    })

    .catch(async (err) => {
      console.log('ERROR singInUpConfirmationCode ==', err, '');

      if (getError(err, 'code') === 'WRONG_CONFIRMATION_CODE')
        setServerResponse({ action: 'WRONG_CONFIRMATION_CODE' });
      else setServerResponse({ action: getError(err) });

      // ------------- emilate response ------------
      // const data = {
      //   authToken:
      //     'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjI3Mjk4MSwicm9sZSI6MSwiYXBwQ29kZSI6ImNvbS5wb3N0bW9kZXJuLm1vYmltZWRSZWFjdCIsIm1lZGljYWxOZXRJZCI6MTUsImxhbmdDb2RlIjoicnVzIiwiaWF0IjoxNjkzNDcyODY3LCJleHAiOjE2OTM1NTkyNjcsImF1ZCI6ImFwaSIsImlzcyI6Ii9hdXRoL2xvZ2luIiwic3ViIjoiNDYuNzIuNjQuNjMiLCJqdGkiOiIwOGJjNTMyOS1iY2Q2LTQyNjktOWZhMi0wYjliOWUyZDM2ZWEifQ.soQugVLTE3rNFdtLgvSuYRie9L-P3L4tKd9ZGnGA_lK5UUv_EANRnxmw-lfjSor72fNrBzdovj_UEhy8q4IPUMH0hT9_puOUsIctsiRbiGOZ0a_TAJCTonBkPUt0eza-N2m200LiAV1XxOE2dZhtUrfaYRGOqWSFVMX2f__4U5I8hGIdG-zYbd5psHZ_PU-qyrLqFXLxAmwlqC57BsFJwEMW_jfsJYa8GFIXlTAf8zgMiWg8zjI8beLGoAFfK7A6mLAqcirqNFZG4LInOgauzswuZbfKLHJBI1IOa6cknqOudtbJKYKDoihp2tTldaKLMca3CZibKdAfuwLzvd9cNw',
      //   refreshToken:
      //     'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjI3Mjk4MSwicm9sZSI6MSwiYXBwQ29kZSI6ImNvbS5wb3N0bW9kZXJuLm1vYmltZWRSZWFjdCIsIm1lZGljYWxOZXRJZCI6MTUsImxhbmdDb2RlIjoicnVzIiwiaWF0IjoxNjkzNDcyODY3LCJleHAiOjE3MDkwMjQ4NjcsImF1ZCI6InJlZnJlc2giLCJpc3MiOiIvYXV0aC9sb2dpbiIsInN1YiI6IjQ2LjcyLjY0LjYzIiwianRpIjoiMDhiYzUzMjktYmNkNi00MjY5LTlmYTItMGI5YjllMmQzNmVhIn0.vkW44zvh2dGbTW_kv3XuibREQtYrrIT_Gkf0JuUqVURtbsQqfEtJLCyac5Scl5UPAhJUCy4IW3WdnJYix6pMk-vzg-_sYLXjZAPV8AVnS378w6lK4BSz1XS0PbTcCieB7G2mt1EWJF9kARnn5AMyZbclBQ6-i0w4br5yt77z-oJhnTZQleXSsChZ1ZaPWsNXOnlq1wOJEc_ApVAVcH_SQWt0qETQl5HvzDWH8m2g92BOGlKNgzpeb1YpeRRjenHQFgOQnSJstOgWqqZKuNQ9ISy8tSr6tpSzsXe6xcc1UiI3uxVN-roXPIe8uYrq9Bq1bAxP5gffAUiVWlyvyjjTmw',
      //   role: 1,
      // };
      // setServerResponse({ ...data, action: 'OK' });
      // localStorage.setItem('authToken', data?.authToken);
      // localStorage.setItem('refreshToken', data?.refreshToken);
      // getUserData(
      //   dispatch,
      //   language,
      //   false,
      //   setServerResponse,
      //   setIsLoading,
      //   data?.role,
      // );
      // -------------  end emilate response ------------

      setIsLoading(false);
    });
}

export async function changeEmailConfirmationCode(
  dispatch,
  values,
  user,
) {
  dispatch({
    type: 'SET_USER',
    payload: { isLoaded: false },
  });

  await httpClient
    .post(
      api.url('ConfirmNewEmail'),
      {
        code: values.confirmationCode,
      },
      {
        config: { isWrap: false }, // needed for old api only
      },
    )
    .then(({ data }) => {
      dispatch({
        type: 'SET_USER',
        payload: {
          isLoaded: true,
          serverResponse: { action: 'OK', ...data },
          user: { ...user, ...values },
        },
      });

      localStorage.setItem(
        'user',
        JSON.stringify({ ...user, ...values }),
      );
    })
    .catch((err) => {
      const userOld = JSON.parse(localStorage.getItem('user'));
      const serverResponse = {
        action: getError(err, 'code'),
        message: getError(err),
      };
      dispatch({
        type: 'SET_PROFILE_CODE_WRONG',
        payload: {
          user: {
            email: userOld.email,
          },
          serverResponse,
        },
      });
    });
}
export async function changePhoneConfirmationCode(
  dispatch,
  values,
  user,
) {
  dispatch({
    type: 'SET_USER',
    payload: { isLoaded: false },
  });

  await httpClient
    .post(
      api.url('ConfirmNewPhone'),
      {
        code: values.confirmationCode,
      },
      {
        config: { isWrap: false }, // needed for old api only
      },
    )
    .then(({ data }) => {
      dispatch({
        type: 'SET_USER',
        payload: {
          isLoaded: true,
          serverResponse: { action: 'OK', ...data },
          user: { ...user, ...values },
        },
      });
      localStorage.setItem(
        'user',
        JSON.stringify({ ...user, ...values }),
      );
    })
    .catch((err) => {
      //console.log('= axios ERROR changePhoneConfirmationCode =', err);
      const userOld = JSON.parse(localStorage.getItem('user'));
      const serverResponse = {
        action: getError(err, 'code'),
        message: getError(err),
      };
      dispatch({
        type: 'SET_PROFILE_CODE_WRONG',
        payload: {
          user: {
            phone: userOld.phone,
          },
          serverResponse,
        },
      });
    });
}

export async function getUserInfo(
  dispatch,

  mmkId = 'parent',
  clinicId = null,
) {
  await dispatch({
    type: 'SET_USER',
    payload: { isLoaded: false },
  });

  await httpClient
    .get(api.url('GetUserInfo'), {
      mmkId,
      clinicId,
    })
    .then(async ({ data }) => {
      const user = JSON.parse(localStorage.getItem('user'));
      const payload = !isEmpty(data)
        ? {
            user: {
              ...user,
              ...data,
              mmkId,
              clinicId,
            },
            isLoaded: true,
          }
        : { user, isLoaded: true };

      await dispatch({
        type: 'SET_USER',
        payload,
      });
    })
    .catch((err) => {
      console.log('ERROR GetUserInfo =', getError(err));
      dispatch({
        type: 'SET_USER',
        payload: {
          isLoaded: true,
          serverResponse: {
            action: getError(err, 'code'),
            message: getError(err),
          },
        },
      });
    });
}

export async function createUser(
  values,
  setIsLoading,
  setServerResponse,
  countryCode = 'RU',
) {
  setServerResponse('');
  setIsLoading(true);
  const login = {};
  if (isValidEmail(values.login)) login.email = values.login;
  else if (isValidPhone(values.login, countryCode))
    login.phone = values.login;

  const body = {
    ...login,
    password: values.password,
    lastName: values.lastName,
    firstName: values.firstName,
    middleName: values.middleName,
    birthDate: values.birthDate,
    gender: values.gender,
    registrationSource: 'web',
  };

  await httpClient
    .post(api.url('RegisterNewUser'), body, {
      config: { isWrap: false },
    })
    .then(({ data }) => {
      // console.log(
      //   '--------- request RegisterNewUser--------- \n',
      //   body,
      //   '--------- response RegisterNewUser--------- \n',
      //   data,
      // );

      setServerResponse({ ...data, action: 'CONFIRM_CODE_SENT' });
      setIsLoading(false);
    })
    .catch((err) => {
      setServerResponse({ action: getError(err) });
      setIsLoading(false);
    });
}

export async function initPasswordChanging(
  values,
  setIsLoading,
  setServerResponse,
) {
  setServerResponse({});
  setIsLoading(true);
  const body = { login: values.login };

  await httpClient
    .post(api.url('InitPasswordChanging'), body, {
      config: { isWrap: false },
    })
    .then(({ data }) => {
      setIsLoading(false);
      setServerResponse({ ...data, action: 'CONFIRM_CODE_SENT' });
    })
    .catch((err) => {
      setServerResponse({ error: getError(err) });
      setIsLoading(false);
    });
}

export async function changePassword(
  values,
  setIsLoading,
  setServerResponse,
) {
  setServerResponse('');
  setIsLoading(true);

  const body = {
    login: values.login,
    confirmationCode: values.confirmationCode,
    newPassword: values.password,
  };

  await httpClient
    .post(api.url('ChangePassword'), body, {
      config: { isWrap: false },
    })
    .then(({ data }) => {
      setIsLoading(false);
      setServerResponse({ action: data });
    })
    .catch((error) => {
      console.log('Err ChangePasswordResult ==', getError(error));
      setServerResponse({
        error: getError(error),
        action: 'WRONG_CONFIRMATION_CODE',
      });
      setIsLoading(false);
    });
}

//  reset auth & localStorage

export function clearLocalStorage() {
  localStorage.removeItem('authToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('user');

  savedEntites.forEach((key) => {
    localStorage.removeItem(key);
  });
}
export async function signOut(
  dispatch = null,

  // path = '/',
  // isRedirect = false,
) {
  try {
    await httpClient.post(api.url('Logout'));
  } catch (error) {
    console.log('ERROR Logout', getError(error));
  }

  clearLocalStorage();
  console.log('\n\n\n\n ===== done SignOut ===== \n\n\n\n ');
  if (dispatch != null) dispatch({ type: 'SIGN_OUT_SUCCESS' });

  // const accessToken = getParam('access_t');
  // if (isRedirect || accessToken) window.location.href = path;
}
export async function getRefreshToken(
  authToken,
  refreshToken,
  signOut,
) {
  try {
    const { data } = await httpClient.post(api.url('RefreshToken'), {
      authToken,
      refreshToken,
    });
    localStorage.setItem('authToken', data?.authToken);
    localStorage.setItem('refreshToken', data?.refreshToken);
    window.location.href = '/';
  } catch (error) {
    console.log('ERROR RefreshToken', getError(error));
    signOut();
  }
}

export async function getDictsData(dispatch) {
  const reqs = [
    {
      name: 'GetFilials',
      body: {
        includeNotVisible: true,
      },
    },

    {
      name: 'AppInfo',
      body: {},
    },
  ];

  const totalRequest = reqs.map((req) =>
    httpClient.get(api.url(req.name), req.body),
  );
  await axios
    .all(totalRequest)
    .then((res) => {
      const dictData = {
        clinics: ifEmptyArr(res[0].data).map((item) => ({
          ...item,
          name_old: item.name,
          name: item.title ? item.title : item.name,
        })),
        appInfo: {
          ...res[1].data,
          countryCode: 'RU',
          phoneMask: '+7 (999) 999 9999',
        },
      };
      Object.keys(dictData).forEach((key) => {
        localStorage.setItem(key, JSON.stringify(dictData[key]));
      });
      // console.log('\n === SAVE DICT DATA ===\n', dictData);

      dispatch({
        type: 'SET_USER',
        payload: dictData,
      });
    })
    .catch((err) => {
      dispatch({
        type: 'SET_USER',
        payload: {
          serverResponseDictsError: getError(err),
        },
      });
      // console.log('==== error getDictsData ==== \n', getError(err));
    });
}

export async function getUserData(
  dispatch,
  lang,

  callFromLogin = false,
  setError = null,
  setIsLoading = null,
  role = accountRole.patient,
) {
  const body = {
    mmkId: 'parent',
    clinicId: 0,
  };

  await httpClient
    .get(api.url('GetUserInfo'), body)

    .then(async ({ data }) => {
      if (callFromLogin) {
        setError(false);
        setIsLoading(false);
      }

      const clinicId = 0;

      const authToken = localStorage.getItem('authToken');
      const phoneParent = data?.phone;
      const userData = {
        user: !isEmpty(data)
          ? {
              ...data,
              photoUrl: data.photoUrl,
              mmkId: 'parent',
              clinicId,
              lang,
              //authData,
              isAnonymous: data.canDeleteAccount,
              role,
              phoneParent,
            }
          : { lang, role },

        isLoaded: true,
        authToken,
      };
      localStorage.setItem('user', JSON.stringify(userData.user));
      console.log('\n === SAVE DATA ===\n', userData);

      dispatch({
        type: 'LOGIN_PATIENT',
        payload: userData,
      });
    })
    .catch((err) => {
      console.log('==== error getUserData ==== \n', getError(err));
      if (callFromLogin) {
        setError(getError(err));
        setIsLoading(false);
      }
    });
}

export async function loginUser(
  dispatch,
  emailOrPhone,
  password,
  setIsLoading,
  setError,
  setValsTab,
  lang,
) {
  if (emailOrPhone.length > 0 && password.length > 0) {
    setIsLoading(true);

    const login = {};
    login['login'] = emailOrPhone;

    await httpClient
      .post(api.url('ExternalLogin'), {
        ...login,
        password,
      })
      .then(({ data }) => {
        localStorage.setItem('authToken', data?.authToken);
        localStorage.setItem('refreshToken', data?.refreshToken);

        if (data?.isPasswordExpired) {
          setValsTab({
            activeTabId: 2,
            login: emailOrPhone,
            warningNote: 'EXP_PASS',
          });
          setIsLoading(false);
          return;
        }
        if (data?.isTwoFactorAuth) {
          setValsTab({
            activeTabId: 0,
            login: emailOrPhone,
            warningNote: 'CONFIRM_AUTH',
            confirmationCodeInfo: data?.confirmationCodeInfo,
          });
          setIsLoading(false);
          return;
        }
        return data;
      })
      .then((data) => {
        if (!isEmpty(data))
          getUserData(
            dispatch,
            lang,
            true,
            setError,
            setIsLoading,
            data?.role,
          );
      })
      .catch((err) => {
        setError(getError(err));
        console.log('Error ExternalLogin --- \n', getError(err));
        setIsLoading(false);
      });
  } else {
    dispatch({ type: 'LOGIN_FAILURE' });
  }
}

export async function otpLogin({
  userDispatch,
  language,
  setIsLoading,
  setServerResponse,

  login,
  code = undefined,
}) {
  const body = {
    login,
    code,
  };
  setIsLoading(true);
  try {
    const { data } = await httpClient.post(api.url('OtpLogin'), body);
    //console.log('otpLogin data ', data);
    if (!isEmpty(data.confirmationCodeInfo)) {
      setServerResponse({
        ...data.confirmationCodeInfo,
        action: 'CONFIRM_CODE_SENT',
      });
    } else if (data.authToken != null) {
      setServerResponse({ ...data, action: 'OK' });
      localStorage.setItem('authToken', data?.authToken);
      localStorage.setItem('refreshToken', data?.refreshToken);
      getUserData(
        userDispatch,
        language,
        false,
        setServerResponse,
        setIsLoading,
        data?.role,
      );
    }
    setIsLoading(false);
    //userDispatch(data);
  } catch (err) {
    const action = getError(err, 'code');
    //if (action==='ACCOUNT_IS_NOT_CONFIRMED'){}

    setServerResponse({
      action,
      text: getError(err),
    });

    // ------------- emilate response ------------
    // const data = {
    //   authToken:
    //     'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjI3Mjk4MSwicm9sZSI6MSwiYXBwQ29kZSI6ImNvbS5wb3N0bW9kZXJuLm1vYmltZWRSZWFjdCIsIm1lZGljYWxOZXRJZCI6MTUsImxhbmdDb2RlIjoicnVzIiwiaWF0IjoxNjkzNDcyODY3LCJleHAiOjE2OTM1NTkyNjcsImF1ZCI6ImFwaSIsImlzcyI6Ii9hdXRoL2xvZ2luIiwic3ViIjoiNDYuNzIuNjQuNjMiLCJqdGkiOiIwOGJjNTMyOS1iY2Q2LTQyNjktOWZhMi0wYjliOWUyZDM2ZWEifQ.soQugVLTE3rNFdtLgvSuYRie9L-P3L4tKd9ZGnGA_lK5UUv_EANRnxmw-lfjSor72fNrBzdovj_UEhy8q4IPUMH0hT9_puOUsIctsiRbiGOZ0a_TAJCTonBkPUt0eza-N2m200LiAV1XxOE2dZhtUrfaYRGOqWSFVMX2f__4U5I8hGIdG-zYbd5psHZ_PU-qyrLqFXLxAmwlqC57BsFJwEMW_jfsJYa8GFIXlTAf8zgMiWg8zjI8beLGoAFfK7A6mLAqcirqNFZG4LInOgauzswuZbfKLHJBI1IOa6cknqOudtbJKYKDoihp2tTldaKLMca3CZibKdAfuwLzvd9cNw',
    //   refreshToken:
    //     'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjI3Mjk4MSwicm9sZSI6MSwiYXBwQ29kZSI6ImNvbS5wb3N0bW9kZXJuLm1vYmltZWRSZWFjdCIsIm1lZGljYWxOZXRJZCI6MTUsImxhbmdDb2RlIjoicnVzIiwiaWF0IjoxNjkzNDcyODY3LCJleHAiOjE3MDkwMjQ4NjcsImF1ZCI6InJlZnJlc2giLCJpc3MiOiIvYXV0aC9sb2dpbiIsInN1YiI6IjQ2LjcyLjY0LjYzIiwianRpIjoiMDhiYzUzMjktYmNkNi00MjY5LTlmYTItMGI5YjllMmQzNmVhIn0.vkW44zvh2dGbTW_kv3XuibREQtYrrIT_Gkf0JuUqVURtbsQqfEtJLCyac5Scl5UPAhJUCy4IW3WdnJYix6pMk-vzg-_sYLXjZAPV8AVnS378w6lK4BSz1XS0PbTcCieB7G2mt1EWJF9kARnn5AMyZbclBQ6-i0w4br5yt77z-oJhnTZQleXSsChZ1ZaPWsNXOnlq1wOJEc_ApVAVcH_SQWt0qETQl5HvzDWH8m2g92BOGlKNgzpeb1YpeRRjenHQFgOQnSJstOgWqqZKuNQ9ISy8tSr6tpSzsXe6xcc1UiI3uxVN-roXPIe8uYrq9Bq1bAxP5gffAUiVWlyvyjjTmw',
    //   role: 1,
    // };
    // setServerResponse({ ...data, action: 'OK' });
    // localStorage.setItem('authToken', data?.authToken);
    // localStorage.setItem('refreshToken', data?.refreshToken);
    // getUserData(
    //   userDispatch,
    //   language,
    //   false,
    //   setServerResponse,
    //   setIsLoading,
    //   data?.role,
    // );
    // -------------  end emilate response ------------

    setIsLoading(false);
    console.log('= axios ERROR otpLogin =', getError(err));
  }
}

export const confirmLogin = async (
  dispatch,
  code,

  setIsLoading,
  setError,
  lang,
) => {
  try {
    setIsLoading(true);
    const { data } = await httpClient.post(api.url('СonfirmLogin'), {
      code,
    });
    console.log('== confirmLogin == ', data);
    localStorage.setItem('authToken', data?.authToken);
    localStorage.setItem('refreshToken', data?.refreshToken);
    if (data?.authToken != null)
      await getUserData(
        dispatch,
        lang,

        true,
        setError,
        setIsLoading,
        data?.role,
      );
  } catch (error) {
    setError(getError(error));
    console.log('Error confirmLogin --- \n', getError(error));
    setIsLoading(false);
  }
};
export async function reqNewAccConfCode({
  setIsLoading,
  setServerResponse,
  login,
  isAccNotConfirm = false,
}) {
  const body = {
    login,
  };
  setIsLoading(true);
  try {
    const { data } = await httpClient.post(
      api.url('ReqNewAccConfCode'),
      body,
    );

    if (!isEmpty(data) && !isAccNotConfirm) {
      setServerResponse({
        ...data,
        action: 'CONFIRM_CODE_SENT',
      });
    }
    if (!isEmpty(data) && isAccNotConfirm) {
      setServerResponse({
        ...data,
        action: 'DO_CONFIRM_NEW_ACCOUT',
        text: 'Введите новый код подтверждения ',
      });
    }

    setIsLoading(false);
    //userDispatch(data);
  } catch (err) {
    const action = getError(err, 'code');

    setServerResponse({
      action,
      text: getError(err),
    });

    setIsLoading(false);
    console.log('= axios ERROR ReqNewAccConfCode =', getError(err));
  }
}
