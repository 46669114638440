// ----------------------------------------------------------------------

import { Theme } from '@mui/material/styles';

export default function Typography(theme: Theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        h1: {
          fontSize: 45,
          fontWeight: 500,
        },
        h2: {
          fontSize: 40,
          fontWeight: 500,
        },
        h3: {
          fontSize: 38,
          fontWeight: 500,
        },
        h4: {
          fontSize: 35,
        },
        h5: {
          fontSize: 32,
          fontWeight: 500,
        },
        h6: {
          fontSize: '18px !important',
          color: theme.palette.text.subtitle2,
        },
        subtitle1: {
          fontWeight: 500,
          lineHeight: 'normal',
          fontSize: '18px !important',
          marginBottom: '0 !important',
        },
        subtitle2: {
          fontWeight: 500,
          lineHeight: 'normal',
          fontSize: '18px !important',
          color: theme.palette.text.subtitle2,
          marginBottom: '0 !important',
        },
        body1: {
          lineHeight: 'normal',
          fontSize: '18px !important',
          marginBottom: '0 !important',
        },
        body2: {
          fontWeight: 400,
          lineHeight: 'normal',
          fontSize: 18,
          color: `${theme.palette.text.subtitle2} !important`,
          marginBottom: 'auto !important',
        },
        caption: {
          lineHeight: 'normal',
          fontSize: 17,
        },
        // overline: {
        //   fontWeight: 500,
        //   lineHeight: 1.5,
        //   fontSize: pxToRem(12),
        //   letterSpacing: 1.1,
        //   textTransform: 'uppercase',
        // },
        button: {
          fontWeight: 500,
          lineHeight: 'normal',
          fontSize: 18,
          //    textTransform: 'capitalize',
        },
      },
    },
  };
}
