import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import { UserProvider } from './context/UserContext';
import ThemeProvider from './themes';
import { AppLanguageProvider } from './context/LanguageContext';
import ErrorBoundary from './components/ErrorBoundary';
import config from './config';

const root = ReactDOM.createRoot(
  document.getElementById('visit-wiget'),
);
root.render(
  <ErrorBoundary>
    <ThemeProvider name={config.theme}>
      <AppLanguageProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </AppLanguageProvider>
    </ThemeProvider>
  </ErrorBoundary>,
);
