import React, { useEffect, useMemo, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

import Loading from '../../components/Loading';

import { useVisitState } from '../../context/VisitContext';
import { doctorFreeDates } from '../../actions/visit';

//import { useTranslation } from 'react-i18next';

import {
  formatStrDateToHL7,
  hl7ToUtc,
  Locales,
} from '../../helpers/dateFormat';
import { lastDayOfMonth } from 'date-fns';

import isEmpty from '../../helpers';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import { Badge } from '@mui/material';
import { useLanguageValue } from '../../context/LanguageContext';

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.hero,
    color: theme.palette.hero,
    //boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    top: 32,
    left: 20,
    width: 7,
    height: 7,
    minWidth: 7,

    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  //'&.MuiBadge-dot': {},
}))(Badge);

const useStyles = makeStyles(() => ({
  content: {
    height: '100%',
  },
}));
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const DoctorCalendar = () => {
  //const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const {
    setState,
    state: {
      selectedDate,
      doctorId,
      selectedClinicId,

      selectedVisitKindId,
      doctor: { plSubjId },
      cachedTimeSlots,
      isOnlineParam,
    },
  } = useVisitState();
  const isOnline = isOnlineParam ? 'Online' : 'Offline';

  const dateNow = new Date();
  dateNow.setHours(0, 0, 0, 0);

  const [docFreeDates, setDocFreeDates] = useState({
    isLoaded: true,
    data: [],
    selectedDate,
  });

  const shouldDisableDate = (date) => {
    return date !== undefined && date < dateNow;
  };

  useEffect(() => {
    doctorFreeDates(
      setState,
      setDocFreeDates,
      docFreeDates.selectedDate,
      selectedClinicId,
      doctorId,
      selectedVisitKindId,
      formatStrDateToHL7(dateNow),
      formatStrDateToHL7(lastDayOfMonth(docFreeDates.selectedDate)),
      plSubjId,
      isOnline,
      cachedTimeSlots,
    );
    // eslint-disable-next-line
  }, [selectedDate, selectedClinicId, selectedVisitKindId]);

  useEffect(() => {
    const selectedDateHl7 = formatStrDateToHL7(selectedDate);

    if (
      docFreeDates.isLoaded &&
      !isEmpty(docFreeDates.data) &&
      !docFreeDates.data.includes(selectedDateHl7)
    ) {
      handleDateChange(hl7ToUtc(docFreeDates.data[0]));
    }
    // eslint-disable-next-line
  }, [docFreeDates.data]);

  // const requestAbortController = React.useRef(null);

  const {
    languageState: { language },
  } = useLanguageValue();

  const handleDateChange = (newSelectedDate) => {
    setDocFreeDates({
      ...docFreeDates,
      selectedDate: newSelectedDate,
    });
    setState({
      type: 'SET_SELECT_DATE',
      payload: newSelectedDate,
    });
  };

  const handleMonthChange = (startDate) => {
    startDate = !shouldDisableDate(startDate) ? startDate : dateNow;

    const visitDatesStart = formatStrDateToHL7(startDate);

    const visitDatesEnd = formatStrDateToHL7(
      lastDayOfMonth(startDate),
    );

    doctorFreeDates(
      setState,
      setDocFreeDates,
      startDate,
      selectedClinicId,
      doctorId,
      selectedVisitKindId,
      visitDatesStart,
      visitDatesEnd,
      plSubjId,
      isOnline,
      cachedTimeSlots,
    );

    // setState({
    //   type: 'SET_SELECT_DATE',
    //   payload: startDate,
    // });
  };

  const isDocFreeDay = (date) =>
    !isEmpty(docFreeDates.data) &&
    docFreeDates.data.includes(formatStrDateToHL7(date));

  const renderDay1 = (
    currentDate,
    selectedDates,
    pickersDayProps,
  ) => {
    const style =
      currentDate.getDate() === docFreeDates.selectedDate.getDate()
        ? {
            style: {
              backgroundColor: theme.palette.hero,
              color: 'white',
            },
          }
        : { style: {} };

    return isDocFreeDay(currentDate) &&
      !pickersDayProps.outsideCurrentMonth ? (
      <StyledBadge
        key={currentDate.toString()}
        overlap="circular"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        variant="dot"
      >
        <PickersDay {...style} {...pickersDayProps} />
      </StyledBadge>
    ) : (
      <PickersDay
        key={currentDate.toString()}
        {...pickersDayProps}
        disabled
      />
    );
  };

  return useMemo(
    () =>
      selectedVisitKindId && (
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={Locales[language]}
        >
          <StaticDatePicker
            //id="date-picker-inline"
            displayStaticWrapperAs="desktop"
            shouldDisableDate={shouldDisableDate}
            value={docFreeDates.selectedDate}
            onChange={handleDateChange}
            onMonthChange={handleMonthChange}
            renderDay={renderDay1}
            loading={!docFreeDates?.isLoaded}
            renderLoading={() => <Loading />}
            views={['day']}
            className={classes.calendar}
          />
        </LocalizationProvider>
      ),
    [docFreeDates.data, selectedVisitKindId],
  );
};

export default DoctorCalendar;
