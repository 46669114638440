import i18next from 'i18next';

export default function validate(values) {
  let errors = {};

  if (values.code != null && values?.code.length < 5) {
    errors.code = i18next.t('SIGN.IN_EMPTY_CONFIRM_CODE');
  }
  // console.log('values', values);
  // console.log('errors', errors);

  return errors;
}
