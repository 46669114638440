import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import DoctorCard from './DoctorCard';
import { useVisitState } from '../../context/VisitContext';
import { formatStrDateToHL7 } from '../../helpers/dateFormat';
import { getDoctor } from '../../actions/visit';
import { useUserStateDispatch } from '../../context/UserContext';
import isEmpty from '../../helpers';
import { Alert, Typography } from '@mui/material';
import AlertDialogSlide from '../../components/Modals/AlertDialogSlide';
const useStyles = makeStyles(() => ({
  paper: {
    padding: 0,
    width: '100%',
    minWidth: 288,
    marginTop: 20,
  },
}));
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function DoctorContainer() {
  //const classes = useStyles();
  const {
    userState: { clinics },
  } = useUserStateDispatch();
  const classes = useStyles();

  const {
    state: {
      doctorId,
      doctor,
      selectedDate,
      // selectedClinicId,
      // selectedSpecId,
      // selectedVisitKindId,
      isOnlineParam,
      filterWithTimeSlotsOnly,
      cachedTimeSlots,
      filterWithPlanningOnly,
    },
    setState,
  } = useVisitState();
  // console.log(
  //   'doctor',
  //   doctor,
  //   'selectedClinicId',
  //   selectedClinicId,
  //   'selectedSpecId',
  //   selectedSpecId,
  //   'selectedVisitKindId',
  //   selectedVisitKindId,
  // );
  const timeSlotsDate = formatStrDateToHL7(selectedDate);
  useEffect(() => {
    if (!isEmpty(clinics) && doctorId != null)
      getDoctor({
        dispatch: setState,
        typeAction: 'FETCH_DOCTOR',
        timeSlotsConsultationType: isOnlineParam
          ? 'Online'
          : 'Offline',
        timeSlotsDate,
        specializationId: null,
        specialization: '',
        selectedName: '',
        selectedClinicId: null,
        selectedVisitKindId: null,
        startIndex: 0,
        count: 100,
        withTimeSlotsOnly: filterWithTimeSlotsOnly,
        favoriteOnly: false,
        doctorId,
        cachedTimeSlots,
        withPlanningOnly: filterWithPlanningOnly,
        clinics,
      });
  }, [doctorId, timeSlotsDate, clinics.length]);

  return (
    <div className={classes.paper}>
      {doctor.responseError != null ? (
        <AlertDialogSlide
          isOpen
          contentTextOk={doctor.responseError}
          severity="warning"
        />
      ) : doctorId != null &&
        isEmpty(doctor.data) &&
        doctor.isLoaded ? (
        <AlertDialogSlide
          isOpen
          contentTextOk={`No data by doctorId: ${doctorId}`}
          severity="warning"
        />
      ) : doctorId == null ? (
        <Alert severity="warning">
          <Typography variant="h6" component="p" mb={4}>
            doctorId is empty
          </Typography>
        </Alert>
      ) : (
        <DoctorCard />
      )}
    </div>
  );
}
