import isEmpty, { ifEmptyArr } from '../helpers';
import httpClient, { getError } from '../api/httpClient';
import api from '../api/apiParams';
import { formatHL7DateToDate } from '../helpers/dateFormat';
export const DEFAULT_COUNT = 5;
export const itemId = (it) =>
  `${it?.doctor?.id}-${it?.doctor?.clinicId}-${it?.doctor?.plSubjId}-${it?.doctor?.specializationInfo}`;
export const itemIdVisitKind = (it) =>
  `${it?.id}-${it?.plExGrWebId}-${it?.specializationId}`;

export function getDefaultVisitKind(visitKinds, isOnline) {
  // в первую очередь фильтруем по online/offline
  const matchedVisitKinds = visitKinds.filter(
    (it) => it.isOnline === isOnline,
  );
  if (matchedVisitKinds.length === 0) return;
  // если они все же есть, то ищем тип приема по-умолчанию
  return (
    matchedVisitKinds.find((it) => it.isDefaultWeb) || // Он либо isDefaultWeb
    matchedVisitKinds.find((it) => it.isDefault) || // либо isDefault
    (isOnline && matchedVisitKinds[0]) ||
    null
  ); // либо первый в списке (если нет isDefaultWeb и isDefault и только для online)
}

let countErrorRequers = 0;
export async function getDoctor({
  dispatch,
  typeAction = 'FETCH_DOCTOR',
  timeSlotsConsultationType = 'Offline', //Offline, Online
  timeSlotsDate,
  specializationId = null,
  specialization = '',

  selectedName = '',
  selectedClinicId = 0,
  selectedVisitKindId = 0,
  startIndex = 0,
  count = DEFAULT_COUNT,
  withTimeSlotsOnly = false,
  favoriteOnly = false,
  doctorId = null,
  cachedTimeSlots = true,
  withPlanningOnly = false,
  sortOrder = false,
  clinics,
}) {
  const body = {
    specializationId,
    specialization,
    startIndex,
    searchValue: selectedName || '',
    clinicId: selectedClinicId || 0,
    visitKindId: selectedVisitKindId || 0,
    withVisitKinds: !selectedVisitKindId ?? true,
    timeSlotsDate,
    count,
    withTimeSlotsOnly,
    favoriteOnly,
    doctorId,
    timeSlotsConsultationType,
    sortOrder: sortOrder ? 'withSlotsFirst' : 'natural',
    cachedTimeSlots,
    withPlanningOnly,
  };
  //console.log('body', body);
  dispatch({
    type: 'DOCTOR_LOADING',
  });
  await httpClient
    .get(api.url('GetDoctors'), body)
    .then(async ({ data }) => {
      await dispatch({
        type: typeAction,
        payload: {
          data,
          clinics,
        },
      });
    })
    .catch((err) => {
      if (
        err?.response?.data?.code === 'SERVER_ERROR' &&
        countErrorRequers < 5
      ) {
        countErrorRequers++;
        console.log(
          '==GetDoctors countErrorRequers',
          countErrorRequers,
        );

        getDoctor({
          dispatch,
          typeAction,
          timeSlotsConsultationType,
          timeSlotsDate,
          specializationId,
          specialization,
          selectedName,
          selectedClinicId,
          selectedVisitKindId,
          startIndex,
          count,
          withTimeSlotsOnly,
          favoriteOnly,
          doctorId,
          cachedTimeSlots,
          withPlanningOnly,
          sortOrder,
          clinics,
        });
        return;
      }
      countErrorRequers = 0;
      dispatch({
        type: 'FETCH_DOCTOR_ERROR',
        payload: getError(err),
      });
    });
}

export async function getDoctorsTimeSlots({
  setState,
  date,
  clinicId,
  doctorId,
  plSubjId,
  visitKindId,
  cached = false,
  consultationType = 'Offline', //Offline, Online,
}) {
  setState({
    type: 'DOCTOR_LOADING',
  });
  const body = {
    doctorId,
    plSubjId,
    clinicId,
    visitKindId,
    date,
    cached,
    consultationType,
  };
  await httpClient
    .get(api.url('GetDoctorsTimeSlots'), body)
    .then(({ data }) => {
      //console.log('GetDoctorsTimeSlots data', data);
      setState({
        type: 'FETCH_DOCTOR_TIME_SLOT',
        payload: ifEmptyArr(data),
      });
    })
    .catch((err) => {
      setState({
        type: 'FETCH_DOCTOR_ERROR',
        payload: getError(err),
      });
    });
}

export async function addVisit({
  setState,
  doctor,
  mmkId,
  visitKindId,
  visitDate,
  contactPhone,
  isOnline = false,
  comment = '',
}) {
  setState({
    type: 'ADD_VISIT_LOADING',
  });
  const body = {
    plSubjId: doctor.plSubjId,
    clinicId: doctor.clinicId,
    mmkId,
    visitKindId,
    visitDate,
    contactPhone,
    comment,
  };

  await httpClient
    .post(api.url('AddVisit'), body)
    .then(({ data }) => {
      console.log('ADD_VISIT data', data);
      setState({
        type: 'ADD_VISIT',
        payload: {
          data,
          visitDate,
          plSubjId: doctor.plSubjId,
          clinicId: doctor.clinicId,
        },
      });
      getDoctorsTimeSlots({
        setState: setState,
        date: formatHL7DateToDate(visitDate),
        clinicId: doctor.clinicId,
        doctorId: doctor.id,
        plSubjId: doctor.plSubjId,
        visitKindId,
        cached: false,
        consultationType: isOnline ? 'Online' : 'Offline',
      });
    })
    .catch((err) => {
      setState({
        type: 'ADD_VISIT_SERVER_ERR',
        payload: getError(err),
      });
    });
}

export async function doctorFreeDates(
  setState,
  setDocFreeDates,
  selectedDate,
  clinicId,
  doctorId,
  visitKindId,
  dateFrom,
  dateTo,
  plSubjId,
  consultationType = 'Offline', //Offline, Online,
  cached = true,
) {
  const body = {
    clinicId,
    doctorId,
    visitKindId,
    dateFrom,
    dateTo,
    plSubjId,
    consultationType,
    cached,
  };
  console.log('--- DoctorFreeDates--- \n', body);
  setDocFreeDates({ isLoaded: false, data: [], selectedDate });
  await httpClient
    .get(api.url('DoctorFreeDates'), body)
    .then(({ data }) => {
      setDocFreeDates({
        isLoaded: true,
        data: ifEmptyArr(data),
        selectedDate,
      });
      if (isEmpty(data)) {
        setState({
          type: 'FETCH_DOCTOR_TIME_SLOT',
          payload: [],
        });
      }
    })
    .catch((err) => {
      setDocFreeDates({ isLoaded: true, data: [], selectedDate }),
        console.log(
          '---Error getting DoctorFreeDates--- \n',
          getError(err),
        );
    });
}

export async function sendRequestEmailToClinic({
  setState,
  lastName,
  firstName,
  middleName,
  phone,
  plSubjId,
  visitDate,
  clinicId,
}) {
  const body = {
    lastName,
    firstName,
    middleName,
    phone,
    plSubjId,
    visitDate,
    clinicId,
  };
  setState({
    type: 'DOCTOR_LOADING',
  });
  await httpClient
    .post(api.url('SendVisitRequestEmailToClinic'), body)
    .then(({ data }) => {
      setState({
        type: 'ADD_VISIT',
        payload: {
          isLoaded: true,
          data,
          visitDate,
          plSubjId,
          clinicId,
        },
      });
    })
    .catch((err) => {
      setState({
        type: 'FETCH_DOCTOR_ERROR',
        payload: getError(err),
      });
    });
}

export async function customCheck({
  setCustomCheck,
  clinicId,
  plSubjId,
  plExamId,
  fmClinkPatientsId,
  param1,
  param2,
  param3,
  param4,
  mmkId,
}) {
  const body = {
    clinicId,
    plSubjId,
    plExamId,
    fmClinkPatientsId,
    param1,
    param2,
    param3,
    param4,
    mmkId,
  };

  try {
    const { data } = await httpClient.post(
      api.url('CustomCheck'),
      body,
    );
    // data = {
    //   status: 2,
    //   statusDescription: null,
    //   msgText:
    //     'Данная услуга может не пройти по вашему договору ДМС и требует дополнительного согласования и может быть платной.',
    // };

    setCustomCheck(data);

    //console.log('GetDoctorReviews data ', data);
  } catch (err) {
    console.log('= axios ERROR CustomCheck =', getError(err));
  }
}
