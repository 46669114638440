import i18next from 'i18next';
import {
  isValidPhone,
  rexFio,
  RANGE_AGE_GROUP,
  notAvalibleBirthDate,
} from '../../helpers/validators';
import moment from 'moment';
import { hl7ToUtc } from '../../helpers/dateFormat';

export const MIN_BIRTH_DATE_CHILD = -18;
export const MAX_BIRTH_DATE_CHILD = 0;

export default function validate(values, _appInfo) {
  const isValidAgeGroup = (values) => {
    if (values.ageGroup === 'Any') return true;
    if (values.birthDate == null) return true;

    const age = moment().diff(hl7ToUtc(values.birthDate), 'years');
    if (values.ageGroup === 'Adult' && age >= RANGE_AGE_GROUP)
      return true;
    if (values.ageGroup === 'Child' && age < RANGE_AGE_GROUP)
      return true;
    if (
      values.ageGroup === 'Child' &&
      age >= RANGE_AGE_GROUP &&
      _appInfo.isSettingChildRepresentativeEnabled
    )
      return true;
    return false;
  };

  let errors = {};

  if (values.firstName != null && !values?.firstName) {
    errors.firstName = i18next.t('COMPONENT.EMPTY_NAME');
  }
  if (values.lastName != null && !values?.lastName) {
    errors.lastName = i18next.t('COMPONENT.EMPTY_LAST_NAME');
  }
  if (values.firstName != null && !rexFio.test(values.firstName)) {
    errors.firstName = i18next.t('SIGN.UP_NOT_VALID_NAME');
  }
  if (values.lastName != null && !rexFio.test(values.lastName)) {
    errors.lastName = i18next.t('SIGN.UP_NOT_VALID_LAST_NAME');
  }

  if (values.middleName && !rexFio.test(values.middleName)) {
    errors.middleName = i18next.t('SIGN.UP_NOT_VALID_MIDDLE_NAME');
  }

  if (
    values.firstName != null &&
    values.lastName != null &&
    values.firstName.trim() === values.lastName.trim()
  ) {
    errors.firstName = i18next.t('SIGN.UP_CANNOT_BE_SAME');
    errors.lastName = i18next.t('SIGN.UP_CANNOT_BE_SAME');
  }

  if (
    values.needPhone &&
    (values.phone === null || values.phone === '')
  ) {
    errors.phone = i18next.t('COMPONENT.EMPTY_PHONE');
  } else if (
    values.needPhone &&
    !isValidPhone(values.phone, _appInfo.countryCode)
  ) {
    errors.phone = i18next.t('COMPONENT.INCORECT_PHONE');
  }

  if (!isValidAgeGroup(values) && values.isMmkLinkedList)
    errors.ageGroup = i18next.t('VISIT.AGE_GROUP_ALERT_MORE');
  if (!isValidAgeGroup(values) && !values.isMmkLinkedList)
    errors.ageGroup = i18next.t('VISIT.AGE_GROUP_ALERT');

  if (
    _appInfo.requireVisitCustomCheck &&
    values.customCheck?.status === null
  ) {
    errors.customCheck = i18next.t('VISIT.WAITING_THE_CUSTOM_CHECK');
  }
  if (
    _appInfo.requireVisitCustomCheck &&
    values.customCheck?.status === 1
  ) {
    delete errors.customCheck;
  }
  if (
    _appInfo.requireVisitCustomCheck &&
    values.customCheck?.status === 2 &&
    !values.customCheck?.checked
  ) {
    errors.customCheck = values.customCheck.msgText;
  }

  if (
    _appInfo.requireVisitCustomCheck &&
    values.customCheck?.status === 3
  ) {
    errors.customCheck = values.customCheck.msgText;
  }
  // console.log(
  //   '_appInfo.requireVisitCustomCheck',
  //   _appInfo.requireVisitCustomCheck,
  // );

  // ----------------------- Child --------------------
  if (values.childBirthDate != null && !values.childBirthDate) {
    errors.childBirthDate = i18next.t('COMPONENT.EMPTY_BIRTHDAY');
  } else if (
    values.childBirthDate != null &&
    notAvalibleBirthDate(values.childBirthDate, [
      MIN_BIRTH_DATE_CHILD,
      MAX_BIRTH_DATE_CHILD,
    ])
  ) {
    errors.childBirthDate = i18next.t('COMPONENT.INVALID_BIRTH_DATE');
  }

  if (values.childFirstName != null && !values?.childFirstName) {
    errors.childFirstName = i18next.t('COMPONENT.EMPTY_NAME');
  }
  if (values.childLastName != null && !values?.childLastName) {
    errors.childLastName = i18next.t('COMPONENT.EMPTY_LAST_NAME');
  }
  if (values?.childFirstName && !rexFio.test(values.childFirstName)) {
    errors.childFirstName = i18next.t('SIGN.UP_NOT_VALID_NAME');
  }
  if (values?.childLastName && !rexFio.test(values.childLastName)) {
    errors.childLastName = i18next.t('SIGN.UP_NOT_VALID_LAST_NAME');
  }

  if (
    values.childMiddleName &&
    !rexFio.test(values.childMiddleName)
  ) {
    errors.childMiddleName = i18next.t(
      'SIGN.UP_NOT_VALID_MIDDLE_NAME',
    );
  }

  if (
    values.childFirstName &&
    values.childLastName &&
    values.childFirstName.trim() === values.childLastName.trim()
  ) {
    errors.childFirstName = i18next.t('SIGN.UP_CANNOT_BE_SAME');
    errors.childLastName = i18next.t('SIGN.UP_CANNOT_BE_SAME');
  }

  if (
    values.childMiddleName &&
    !rexFio.test(values.childMiddleName)
  ) {
    errors.childMiddleName = i18next.t(
      'SIGN.UP_NOT_VALID_MIDDLE_NAME',
    );
  }

  // console.log('values', values);
  // console.log('errors', errors);

  return errors;
}
