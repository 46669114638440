// ----------------------------------------------------------------------

import { Theme } from '@mui/material/styles';
//import { pxToRem } from '../../paletteFuncs';

export default function IconButton(theme: Theme) {
  return {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#fff',
          lineHeight: 1.5,
          fontSize: 18,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#fff',
          lineHeight: 1.5,
          fontSize: 18,
          backgroundColor: theme.palette.primary.light,
          fontWeight: 500,

          '& span > svg': {
            color: theme.palette.hero,
          },
        },
      },
    },
  };
}
