import React, { useEffect, useState } from 'react';
import SignIn from './SignIn';
import SignUp from './SignUp';
import SignInConfirm from './SignInConfirm';
import {
  createUser,
  singInUpConfirmationCode,
  loginUser,
  otpLogin,
  reqNewAccConfCode,
} from '../../actions/user';
import useForm from '../../hooks/useForm';
import { validate as validateSignUp } from '../../pages/sign_in_up/validationSignUp';
import validateConfirmSignUp from '../../pages/sign_in_up/validationConfirmSignUp';
import validateSignIn from '../../pages/sign_in_up/validationSignIn';
import validateOtpLogin from './validationOtpLogin';
import validateSignInCode from '../../pages/sign_in_up/validationSignInCode';
import { cleanPhoneValue } from '../../helpers/utils';

import { formatDateToHL7 } from '../../helpers/dateFormat';

import { useUserStateDispatch } from '../../context/UserContext';
// import { sendRequestEmailToClinic } from '../../actions/visit';
// import { useVisitState } from '../../context/VisitContext';
import ChangePassword from '../sign_in_up/ChangePassword';
import { makeStyles } from '@mui/styles';
import { useLanguageValue } from '../../context/LanguageContext';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OptLogin from './OtpLogin';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  conteiner: {
    [theme.breakpoints.up('sm')]: {
      margin: `0 ${theme.spacing(4)}`,
      minWidth: theme.spacing(61),
    },
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function Sign() {
  const classes = useStyles();
  const {
    languageState: { language },
  } = useLanguageValue();

  const [valsTab, setValsTab] = useState({
    activeTabId: 100,
    ...config.defLoginPassword,
  });

  const setActiveTabId = (activeTabId) => {
    setValsTab({
      ...valsTab,
      activeTabIdPrev: valsTab.activeTabId,
      activeTabId,
    });
  };
  // local
  const [isLoading, setIsLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState();

  const {
    userDispatch,
    userState: { appInfo },
  } = useUserStateDispatch();
  const { t } = useTranslation();

  const quickLogin = () => {
    otpLogin({
      userDispatch,
      language,
      setIsLoading,
      setServerResponse,
      login: cleanPhoneValue(values.login),
      code: values.code === null ? undefined : values.code,
    });
  };

  const login = () =>
    loginUser(
      userDispatch,
      values?.login,
      values?.password,
      setIsLoading,
      setServerResponse,
      setValsTab,
      language,
    );

  const signUp = () =>
    createUser(
      {
        ...values,
        birthDate:
          values.birthDate != null &&
          formatDateToHL7(values.birthDate),
      },
      setIsLoading,
      setServerResponse,
      appInfo.countryCode,
    );

  const confirmSignUp = () => {
    const codeObj = values.code
      ? { confirmationCode: values.code }
      : { confirmationCode: values.confirmationCode };
    singInUpConfirmationCode(
      {
        ...codeObj,
        login: values.login,
        birthDate:
          values.birthDate != null &&
          formatDateToHL7(values.birthDate),
      },
      setIsLoading,
      setServerResponse,
      userDispatch,
      language,
    );
  };
  const getNewCode = (isAccNotConfirm = false) => {
    setServerResponse(null);
    setValues({ login: cleanPhoneValue(values.login) });
    setErrors({});

    reqNewAccConfCode({
      setIsLoading,
      setServerResponse,
      login: cleanPhoneValue(values.login),
      isAccNotConfirm,
    });
  };

  useEffect(() => {
    if (
      // if catch this response switch SignUpForm
      serverResponse?.action === 'WRONG_LOGIN_OR_CONFIRMATION_CODE'
    ) {
      setActiveTabId(1);
      setServerResponse(null);
    }
    if (
      // if code expired
      serverResponse?.action === 'CONFIRMATION_CODE_EXPIRED' ||
      serverResponse?.action === 'NUMBER_OF_ATTEMPTS_EXCEEDED'
    ) {
      const tout = setTimeout(() => {
        setActiveTabId(100);
        setServerResponse(null);
        setValues({});
        setErrors({});
      }, 3500);
      return () => clearTimeout(tout);
    }

    // if (serverResponse?.action === 'ACCOUNT_IS_NOT_CONFIRMED') {
    //   const tout = setTimeout(() => {
    //     getNewCode();
    //   }, 3500);
    //   return () => clearTimeout(tout);
    // }
    // eslint-disable-next-line
  }, [serverResponse?.action]);

  const isConfirmForm =
    serverResponse?.action === 'CONFIRM_CODE_SENT' ||
    serverResponse?.action === 'DO_CONFIRM_NEW_ACCOUT' ||
    serverResponse?.action === 'WRONG_CONFIRMATION_CODE';

  let submit,
    validate = null;
  if (valsTab.activeTabId === 100) {
    submit =
      serverResponse?.action === 'DO_CONFIRM_NEW_ACCOUT'
        ? confirmSignUp
        : quickLogin;
    validate = isConfirmForm ? validateSignInCode : validateOtpLogin;
  }
  if (valsTab.activeTabId === 0) {
    submit = login;
    validate = validateSignIn;
  }
  if (valsTab.activeTabId === 1) {
    submit = isConfirmForm ? confirmSignUp : signUp;
    validate = isConfirmForm ? validateConfirmSignUp : validateSignUp;
  }
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
  } = useForm(submit, validate, appInfo);

  console.log('serverResponse', serverResponse, 'values', values);

  return (
    <Box className={classes.conteiner}>
      {valsTab.activeTabId === 100 ? (
        <OptLogin
          //setActiveTabId={setActiveTabId}
          serverResponse={serverResponse}
          //setServerResponse={setServerResponse}
          valsTab={valsTab}
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setValues={setValues}
          setErrors={setErrors}
          isLoading={isLoading}
          validate={validate}
          isConfirmForm={isConfirmForm}
          getNewCode={getNewCode}
        />
      ) : valsTab.activeTabId === 0 ? (
        valsTab?.warningNote === 'CONFIRM_AUTH' ? (
          <>
            <Typography
              variant="h5"
              sx={{ textAlign: 'center', marginBottom: 3 }}
            >
              {t('SIGN.CH_CONFIRM_CODE')}
            </Typography>
            <SignInConfirm />
          </>
        ) : (
          <SignIn
            setActiveTabId={setActiveTabId}
            values={values}
            errors={errors}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            serverResponse={serverResponse}
          />
        )
      ) : valsTab.activeTabId === 1 ? (
        <SignUp
          valsTab={valsTab}
          setActiveTabId={setActiveTabId}
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setValues={setValues}
          setErrors={setErrors}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          serverResponse={serverResponse}
          setServerResponse={setServerResponse}
          validateSignUp={validateSignUp}
          isConfirmForm={isConfirmForm}
          getNewCode={getNewCode}
        />
      ) : valsTab.activeTabId === 2 ? (
        <>
          <Typography
            variant="h5"
            sx={{ textAlign: 'center', marginBottom: 3 }}
          >
            {t('SIGN.CH_PASS_BUTTON')}
          </Typography>
          <ChangePassword setValsTab={setValsTab} valsTab={valsTab} />
          <Button
            color="primary"
            variant="text"
            onClick={() => setActiveTabId(0)}
            style={{
              width: '100%',
              marginTop: 24,
              marginBottom: 24,
            }}
          >
            {t('COMPONENT.BACK_ONE_STEP')}
          </Button>
        </>
      ) : null}
    </Box>
  );
}

export default Sign;
