import { parsePhoneNumberFromString as parsePhoneNumberFromStringMobile } from 'libphonenumber-js/mobile';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import moment from 'moment';
import { isValid } from 'date-fns';
export const RANGE_AGE_GROUP = 18;
export const rexFio = /^[_a-zßàâäçèéêëîïôöùûüÿа-яё’-]+$/i;

export const notAvalibleBirthDate = (birthDate, limits) => {
  const [min, max] = limits;

  const nowYear = moment(birthDate);
  const minYear = moment().add(-1, 'day').add(min, 'years');
  const maxYear = max === 0 ? moment() : moment().add(max, 'years');
  return (
    !isValid(birthDate) || nowYear < minYear || nowYear > maxYear
  );
};

export const isPastDate = (dateIn, range = 'days') => {
  const diff = moment().diff(dateIn, range);
  //console.log('diff', diff);
  return isValid(dateIn) && diff > 0;
};

export const isValidEmail = (email) => {
  // 1. Должна быть не пустая строка
  if (!email || typeof email !== 'string') return false;
  // 2. Должны быть 2 части, разделенные @
  const idx = email.lastIndexOf('@');
  if (idx === -1) return false;

  const account = email.slice(0, idx);
  const address = email.slice(idx + 1);

  // 3. Длины частей
  if (account.length > 64 || address.length > 255) return false;

  // 4. Есть точка в адресе
  if (!address.includes('.')) return false;

  // 5. Длины адресной составляющей
  const domainParts = address.split('.');
  if (domainParts.some((part) => part.length > 63)) return false;

  return true;
};
export const parsePhoneNumber = (defaultCountry) =>
  defaultCountry !== 'RU'
    ? parsePhoneNumberFromString
    : parsePhoneNumberFromStringMobile;

export const isValidPhone = (value, defaultCountry = 'RU') => {
  if (value == null) return false;
  console.log('defaultCountry', defaultCountry);

  const phoneNumber = parsePhoneNumber(defaultCountry)(value, {
    defaultCountry,
    extract: false,
  });
  const isPhoneNumberValid = !!(phoneNumber && phoneNumber.isValid());
  return isPhoneNumberValid;
};
