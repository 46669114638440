import React from 'react';

import { useTranslation } from 'react-i18next';
import { useVisitState } from '../../context/VisitContext';
import isEmpty from '../../helpers';
import {
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function Clinics() {
  const { t } = useTranslation();
  const {
    state: {
      doctor: { clinics, isLoaded },
      selectedClinicId,
    },
    setState,
  } = useVisitState();
  //console.log('isLoaded', isLoaded);

  const handleChange = (e) => {
    setState({
      type: 'SET_CLINIC',
      payload: e.target.value,
    });
  };
  const value = (clinics || []).find(
    (item) => item?.id === parseInt(selectedClinicId, 10),
  )?.id;

  return (
    !isEmpty(clinics) && (
      <FormControl fullWidth>
        <InputLabel id="clinics-label">
          {t(`COMPONENT.CLINICS`)}
        </InputLabel>
        <Select
          variant="outlined"
          labelId="clinics-label"
          id="clinics"
          value={value}
          label={t(`COMPONENT.CLINICS`)}
          onChange={handleChange}
          disabled={!isLoaded}
        >
          {clinics.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <Typography variant="body1">
                {item.name}
                <Typography variant="body2">
                  {item.address}
                </Typography>
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  );
}
