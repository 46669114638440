import React, { useReducer, useContext, createContext } from 'react';
import { savedEntites } from '../actions/user';
import config from '../config';
import isEmpty from '../helpers';
const UserContext = createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN_PATIENT':
      return { ...state, ...action.payload, isAuthenticated: true };

    case 'SIGN_OUT_SUCCESS':
      console.log(' ==== SIGN_OUT_SUCCESS === ');
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticatedDoctor: false,
        serverResponse: null,
        user: {},
        doctor: {},
      };
    case 'SIGN_UP_AGREEMENT':
      return { ...state, ...action.payload };
    case 'SET_USER':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_MMK_LINKED_LIST':
      return {
        ...state,
        mmkLinkedList: action.payload,
      };
    case 'SET_PROFILE_CODE_WRONG':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user,
        },
        serverResponse: action.payload.serverResponse,
        isLoaded: true,
      };

    case 'LOGIN_DOCTOR':
      localStorage.setItem(
        'doctor',
        JSON.stringify({
          ...action.payload.doctor,
        }),
      );
      localStorage.setItem(
        'user',
        JSON.stringify({
          ...action.payload.user,
        }),
      );
      localStorage.setItem(
        'mmkRecordTypes',
        JSON.stringify(action.payload.mmkRecordTypes),
      );
      localStorage.setItem(
        'specs',
        JSON.stringify(action.payload.specs),
      );

      return {
        ...state,
        ...action.payload,
        isAuthenticatedDoctor: true,
        loading: false,
        error: '',
      };
    case 'SET_DOC_DATA':
      return {
        ...state,
        doctor: {
          ...action.payload,
        },
        loading: false,
        error: '',
      };
    case 'SET_CLINICS':
      localStorage.setItem('clinics', JSON.stringify(action.payload));
      return {
        ...state,
        clinics: action.payload,
        loading: false,
        error: '',
      };
    case 'LOADING':
      return {
        ...state,
        loading: true,
        error: '',
      };
    case 'SET_CHAT_USER':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
        loading: false,
        error: '',
      };

    case 'SET_SERVER_RESPONSE':
      return {
        ...state,
        loading: false,
        serverResponse: action.payload,
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        loading: false,
        serverResponse: { message: 'Something went wrong' },
      };
    case 'LOGIN_DOCTOR_FAILURE':
      return {
        ...state,
        loading: false,
        serverResponse: 'Something went wrong',
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function UserProvider({ children }) {
  const authToken = localStorage.getItem('authToken');
  const doctor = JSON.parse(localStorage.getItem('doctor')) ?? {};
  const clinics = JSON.parse(localStorage.getItem('clinics')) ?? [];

  const user = JSON.parse(localStorage.getItem('user')) ?? {
    lang: config.defLang,
    clinicId: 0,
    mmkId: null,
  };

  const isAuthenticated =
    authToken != null && user?.mmkId != null && isEmpty(doctor);

  const mmkLinkedList =
    JSON.parse(localStorage.getItem('mmkLinkedList')) ?? [];

  const isAuthenticatedDoctor =
    !isAuthenticated && !!authToken && !isEmpty(doctor);

  const [userState, userDispatch] = useReducer(userReducer, {
    /** user  */
    isAuthenticated,
    user,
    mmkLinkedList,
    ...savedEntites.reduce((acc, cur) => {
      acc[cur] = JSON.parse(localStorage.getItem(cur));
      return acc;
    }, {}),
    isLoaded: true,
    serverResponse: null,
    /** doctor  */
    isAuthenticatedDoctor,
    doctor: { ...doctor, step: 0 },
    loading: false,
    error: '',
    clinics,
    authToken,
  });

  return (
    <UserContext.Provider value={{ userState, userDispatch }}>
      {children}
    </UserContext.Provider>
  );
}

function useUserStateDispatch() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(
      'useUserStateDispatch must be used within a UserProvider',
    );
  }
  return context;
}

export { UserProvider, useUserStateDispatch };
