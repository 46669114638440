// ----------------------------------------------------------------------

import { Theme } from '@mui/material';
//import { pxToRem } from '../../paletteFuncs';

export default function Menu(theme: Theme) {
  return {
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: theme.palette.primary.main,
          boxShadow: '0 0 150px rgba(0, 0, 0, 0.2)',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          // lineHeight: 1.1,
          // fontSize: pxToRem(26),
          color: theme.palette.text.subtitle2,
          padding: `${theme.spacing(1.5)} ${theme.spacing(2.5)}`,
          backgroundColor: theme.palette.primary.main,
          '&:hover': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.dark,
          },

          '&.Mui-selected': {
            '&.Mui-focusVisible': {
              background: theme.palette.primary.light,
            },
          },
        },
      },
    },
  };
}
