import i18next from 'i18next';
import isEmpty from '../../helpers';

export default function validate(values) {
  let errors = {};
  /**/
  if (isEmpty(values.confirmationCode)) {
    errors.confirmationCode = i18next.t(
      'SIGN.UP_NOT_VALID_CONFIRM_CODE',
    );
  }

  if (values.password != null) {
    /*
  /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[,.!@#$%^&*_-]).{5,}$/
  Пояснение:
  (?=.*[0-9]) - строка содержит хотя бы одно число;
  (?=.*[a-z]) - строка содержит хотя бы одну латинскую букву в нижнем регистре;
  (?=.*[A-Z]) - строка содержит хотя бы одну латинскую букву в верхнем регистре;
  (?=.*[,.!@#$%^&*_-]) - строка содержит хотя бы один символ 
  .{5,} - строка состоит не менее, чем из 5 вышеупомянутых символов.
  */

    const checkRules = {
      ONE_DIGIT: /(?=.*[0-9])/,
      ONE_UPPER_LETTER: /(?=.*[A-Z])/,
      ONE_LOWECASE_LAEER: /(?=.*[a-z])/,
      ONE_SYMBOL: /(?=.*[,.!@#$%^&*_-])/,
      MIN_LENGTH: '.{5,}',
    };

    Object.keys(checkRules).forEach((key) => {
      const regex = new RegExp(checkRules[key]);
      errors = {
        ...errors,
        passRules: {
          ...errors?.passRules,
          [key]: {
            valid: regex.test(values.password),
            ruleText: i18next.t(`SIGN.UP_${key}`),
          },
        },
      };
    });

    if (
      Object.keys(errors?.passRules).filter(
        (key) => !errors?.passRules[key].valid,
      ).length === 0
    ) {
      delete errors?.passRules;
    } else {
      errors.password = i18next.t('SIGN.UP_NOT_VALID_PASS');
    }
  }
  if (
    values.repassword != null &&
    values.password !== values.repassword
  ) {
    errors.repassword = i18next.t('SIGN.UP_NOT_MATCH_PASS');
  }

  return errors;
}
