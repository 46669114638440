import i18next from 'i18next';
import { isValidPhone } from '../../helpers/validators';

export default function validate(values, _appInfo) {
  let errors = {};

  if (values.login === null || values.login === '') {
    errors.login = i18next.t('COMPONENT.EMPTY_PHONE');
  } else if (!isValidPhone(values.login, _appInfo.countryCode)) {
    errors.login = i18next.t('COMPONENT.INCORECT_PHONE');
  }

  return errors;
}
