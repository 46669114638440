import { Theme, alpha } from '@mui/material/styles';
//import { pxToRem } from '../../paletteFuncs';

export default function Input(theme: Theme) {
  return {
    MuiInputBase: {
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            lineHeight: 'normal',
            fontSize: 18,
          },
        },
      },
      styleOverrides: {
        root: {
          lineHeight: 'normal',
          fontSize: 20,
          '& svg': {
            color: theme.palette.primary.main,
          },
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.disabled },
          },
        },
        // input: {
        //   '&::placeholder': {
        //     opacity: 1,
        //     color: theme.palette.text.disabled,
        //   },
        // },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          lineHeight: 'normal',
          fontSize: 18,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        lineHeight: 1.5,
        fontSize: 18,
        underline: {
          '&:before': {
            borderBottom: '1px solid rgb(196 196 196)',
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid rgb(97, 87, 255, 0.3)',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(theme.palette.grey[500], 0.12),
          '&:hover': {
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.action.focus,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: alpha(theme.palette.grey[500], 0.56),
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: theme.spacing(2),
          //backgroundColor: 'transparent',
          fontWeight: 500,
          fontSize: 18,
          padding: 4,
          backgroundColor: `${theme.palette.grey[200]} !important`,
          //borderColor: `${theme.palette.grey[200]} !important`,
          '&.Mui-focused': {
            //backgroundColor: '#fff',
            backgroundColor: `${theme.palette.grey[200]} !important`,
            //borderColor: `${theme.palette.grey[200]} !important`,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[300],
          },

          '& fieldset': {
            borderColor: theme.palette.primary.light,
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.primary.main, 0.2),
          },
          '&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.primary.main, 0.2),
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.light, // root border color
            borderWidth: '2px',
          },
          '&.Mui-focused fieldset': {
            borderWidth: '2px',
          },
        },
      },
    },
  };
}
