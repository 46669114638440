import palette from './palette';
import typography from './typography';

import componentsOverride from './overrides';

export default {
  palette,
  typography,

  componentsOverride,
};
